import React, { useState } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

const styles = (theme) => ({
	stepWrapper: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	stepContent: {
		margin: theme.spacing(1),
	},
	paper: {
		textAlign: "center",
		padding: theme.spacing(1),
	},
})

const CoordinateTranslator = ({ classes, setConfigTrans, scale, xTrans, yTrans, markerRadius, setCanContinue }) => {
	const [translations, setTranslations] = useState({ scale, xTrans, yTrans, markerRadius })
	const [debounceTimer, setDebouncerTimer] = useState(null)

	const handleChange = (name) => (val) => {
		const newTranslations = { ...translations, [name]: val.target.value }
		setTranslations(newTranslations)
		if (debounceTimer) {
			clearTimeout(debounceTimer)
		}
		setDebouncerTimer(
			setTimeout(() => {
				setConfigTrans(
					newTranslations.scale,
					newTranslations.xTrans,
					newTranslations.yTrans,
					newTranslations.markerRadius,
				)
			}, 350),
		)
	}

	return (
		<div>
			<div className={classes.stepWrapper}>
				<Typography variant="subtitle1">Scale and Translate X/Y:</Typography>
				<Button
					variant="contained"
					color="secondary"
					className={classes.stepContent}
					onClick={() => {
						setConfigTrans(null, null, null, 0.5)
						setTranslations({})
					}}
				>
					Reset
				</Button>
			</div>
			<Paper className={classes.paper}>
				<Typography variant="h6">Translations</Typography>
				<TextField
					id="scale"
					label="Scale"
					type="number"
					margin="normal"
					variant="outlined"
					value={translations.scale || ""}
					onChange={handleChange("scale")}
					inputProps={{ step: "any" }}
					fullWidth
				/>
				<TextField
					id="xTrans"
					label="Translate X"
					type="number"
					margin="normal"
					variant="outlined"
					value={translations.xTrans || ""}
					onChange={handleChange("xTrans")}
					inputProps={{ step: "any" }}
					fullWidth
				/>
				<TextField
					id="yTrans"
					label="Translate Y"
					type="number"
					margin="normal"
					variant="outlined"
					value={translations.yTrans || ""}
					onChange={handleChange("yTrans")}
					inputProps={{ step: "any" }}
					fullWidth
				/>
				<TextField
					id="markerRadius"
					label="Marker Size"
					type="number"
					margin="normal"
					variant="outlined"
					value={translations.markerRadius || ""}
					onChange={handleChange("markerRadius")}
					inputProps={{ step: "any" }}
					fullWidth
				/>
			</Paper>
		</div>
	)
}

CoordinateTranslator.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CoordinateTranslator)

import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

// The value the master firmware expects to receive for a specific generation and board version.
// Gen3.0 means board version 2.7 or 2.8. Gen3.1 means board version 2.9.
// First byte: CPU type
// Second byte: Board gen
const GenMapping = {
	"Gen3.0": 0x2133,
	"Gen3.1": 0x3131,
}

const useStyles = makeStyles((_theme) => ({
	outer: {
		display: "table",
		border: "1px solid #CCC",
		borderRadius: "4px",
		boxSizing: "border-box",
		padding: "10px",
		textAlign: "left",
		marginTop: "5px",
		marginBottom: "5px",
		width: "100%",
	},
	row: {
		display: "table-row",
	},
	cell: {
		display: "table-cell",
		padding: "2px 4px",
	},
	select: {
		width: "100%",
	},
}))

const NodeTypeSelector = (props) => {
	const { value, setValue } = props
	const classes = useStyles()

	useEffect(() => {
		// Set initial value to Gen3.0 (board v. 2.7 | 2.8) = 0x2133
		setValue(GenMapping["Gen3.0"])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleNodeTypeChanged = useCallback(
		(e) => {
			setValue(e.target.value)
		},
		[setValue],
	)

	return (
		<div className={classes.outer}>
			<div className={classes.row}>
				<div className={classes.cell}>Select node type</div>
				<div className={classes.cell}>
					<select className={classes.select} value={value} onChange={handleNodeTypeChanged}>
						{Object.entries(GenMapping).map(([label, identifier]) => {
							return (
								<option key={label} value={identifier}>
									{label}
								</option>
							)
						})}
					</select>
				</div>
			</div>
		</div>
	)
}

NodeTypeSelector.propTypes = {
	setValue: PropTypes.func.isRequired,
	value: PropTypes.number.isRequired,
}

export default NodeTypeSelector

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"

const styles = (theme) => ({
	stepWrapper: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	stepContent: {
		margin: theme.spacing(1),
	},
	paper: {
		textAlign: "center",
		padding: theme.spacing(1),
	},
})

const MasterCoordinates = ({ classes, masterCoordsAssigned, setCanContinue }) => {
	useEffect(() => {
		// NOTE: As of 2021-04-10, all nodes *must* be assigned to a master, but it's not detrimental to the saving of the
		// masters that some *masters* are unassigned or not positioned. Therefore, we use the `some` method here.
		if (masterCoordsAssigned.some((element) => element.assigned)) {
			setCanContinue(true)
		} else {
			setCanContinue(false)
		}
	}, [masterCoordsAssigned, setCanContinue])

	return (
		<div>
			<Paper className={classes.paper}>
				<Typography variant="h6">Master Locations</Typography>
				{masterCoordsAssigned.map((master) => (
					<TextField
						id={master.mLocId}
						key={master.mLocId}
						label={master.name}
						error={!master.assigned}
						type="text"
						margin="normal"
						variant="outlined"
						value={master.assigned ? "Assigned" : "Unassigned"}
						inputProps={{ step: "any" }}
						disabled
						fullWidth
					/>
				))}
			</Paper>
		</div>
	)
}

MasterCoordinates.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MasterCoordinates)

import React, { useEffect, useMemo, useRef, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import MasterSelect from "components/MasterSelect"
import Button from "@material-ui/core/Button"
import InputLabel from "@material-ui/core/InputLabel"
import Checkbox from "@material-ui/core/Checkbox"
import FormControl from "@material-ui/core/FormControl"
import TextField from "@material-ui/core/TextField"
import { commandParams, getCommands } from "constants/commands"

import API, { graphqlOperation } from "@aws-amplify/api"
import { createCommandRequest } from "graphql/mutations"
import { subscribeCommandFeedback as subscribeCommandFeedbackGql } from "graphql/subscriptions"
import { getChargingLabel } from "constants/charging"
import { getAlarmsString, getFlagsString } from "constants/statusBits"
import PropTypes from "prop-types"
import { nodeParameters } from "../../../constants/nodeParameters"
import SelectSingle from "../../InputFields/SelectSingle"

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	input: {
		margin: "auto",
		marginTop: theme.spacing(1.5),
		textAlign: "center",
	},
	formControl: {
		minWidth: "200px",
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	feedback: {
		flex: 1,
		margin: "auto",
		textAlign: "center",
	},
	paramSelect: {
		display: "flex",
		alignItems: "center",
	},
	dataType: {
		margin: 0,
	},
	dataFeedback: {
		marginTop: theme.spacing(1),
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
	},
	feedbackContainer: {
		minWidth: "200px",
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		textAlign: "left",
		maxWidth: "500px",
		overflow: "wrap",
	},
	feedbackEntries: {
		"& :nth-child(even)": {
			backgroundColor: "#F5F5F5",
		},
	},
	feedbackEntry: {
		padding: theme.spacing(1),
	},
	feedbackMasterName: {
		padding: theme.spacing(1),
		fontWeight: "bold",
	},
}))

const Controls = ({
	privileges,
	masterDetails,
	nodeFwVersion,
	mLocId,
	layoutId,
	layoutName,
	userId,
	username,
	deviceDetailsType,
	nId,
	deviceType,
}) => {
	const [selectedMasters, setSelectedMasters] = useState([mLocId])
	const [command, setCommand] = useState()
	const [args, setArgs] = useState({})
	const [status, setStatus] = useState({}) // type => undefined, sending, sent, received, error
	const [feedback, setFeedback] = useState({})
	const [checkedOptions, setCheckedOptions] = useState([])
	const [subscriptions, setSubscriptions] = useState([])
	const classes = useStyles()

	const inputLabel = useRef(null)
	const myForm = useRef(null)

	const Feedback = ({ feedbackEntries, masterName }) => {
		const classes = useStyles()

		return (
			<div className={classes.feedbackContainer}>
				<span className={classes.feedbackMasterName}>{masterName}</span>
				<div className={classes.feedbackEntries}>
					{feedbackEntries.map((message, index) => (
						<div key={index} className={classes.feedbackEntry}>
							{message}
						</div>
					))}
				</div>
			</div>
		)
	}

	Feedback.propTypes = {
		feedbackEntries: PropTypes.arrayOf(PropTypes.string),
		masterName: PropTypes.string.isRequired,
	}

	const { commands, commandLookup } = useMemo(
		() => getCommands(privileges, deviceType, deviceDetailsType),
		[privileges, deviceType, deviceDetailsType],
	)

	useEffect(() => {
		myForm.current.reset()
		setCheckedOptions([])

		if (!command) return

		// Display defaults
		const params = commandLookup[command].paramSelectDependencies || []
		let newParamValues = {}

		// Add default values from parameter definition
		for (const param of params) {
			const paramDetails = commandParams[deviceType][param]
			if (paramDetails.value !== undefined) {
				newParamValues[param] = paramDetails.value
			}
		}

		// Add default values from command definition
		newParamValues = { ...newParamValues, ...(commandLookup[command].params || {}) }

		setArgs(newParamValues)
	}, [deviceType, setArgs, command, commandLookup])

	const handleCommandChange = (identifier) => {
		setCommand(identifier)
		setArgs((commandLookup[identifier] || {}).params || {})
	}

	useEffect(() => {
		setStatus({})
		subscriptions.forEach((sub) => sub())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [command, selectedMasters, args, checkedOptions])

	const _formatRequestNodeRealtimeStatusMessage = (message) => {
		const dataArray = message.toString().slice(0, -1).split(",")

		if (dataArray.length > 13) {
			return (
				<div className={classes.dataFeedback}>
					<p className={classes.dataType}>
						Software Version: {dataArray[11]}.{dataArray[12]}.{dataArray[13]}
					</p>
					<p className={classes.dataType}>Panel Angle: {(dataArray[2] / 100).toFixed(2)}°</p>
					<p className={classes.dataType}>Target Angle: {(dataArray[3] / 100).toFixed(2)}°</p>
					<p className={classes.dataType}>
						Goal Angle: {dataArray.length >= 15 ? (dataArray[14] / 100).toFixed(2) : "N/A"}°
					</p>
					<p className={classes.dataType}>Angle Table Index: {dataArray[4]}</p>
					<p className={classes.dataType}>Battery Voltage: {(dataArray[5] / 1000).toFixed(2)} (V)</p>
					<p className={classes.dataType}>Motor Current: {(dataArray[6] / 1000).toFixed(2)} (A)</p>
					<p className={classes.dataType}>Charging Current: {(dataArray[8] / 1000).toFixed(2)} (A)</p>
					<p className={classes.dataType}>Battery State of Charge: {dataArray[22]} (%)</p>
					<p className={classes.dataType}>Battery State of Health: {dataArray[23]} (%)</p>
					<p className={classes.dataType}>
						Charger Status: {dataArray[9]} ({getChargingLabel(parseInt(dataArray[9]))})
					</p>
					<p className={classes.dataType}>
						Alarms: {getAlarmsString(privileges, deviceType)(BigInt(dataArray[7]).toString(16))}
					</p>
					<p className={classes.dataType}>
						Flags: {getFlagsString(privileges, deviceType)(BigInt(dataArray[15]).toString(16), nodeFwVersion)}
					</p>
					<p className={classes.dataType}>Tracking State: {dataArray[10]}</p>
					<p className={classes.dataType}>MCU Type: {dataArray[16]}</p>
					<p className={classes.dataType}>Hardware Version: {dataArray[17]}</p>
					<p className={classes.dataType}>Battery Current Draw: {(dataArray[18] / 1000).toFixed(2)} (A)</p>
					<p className={classes.dataType}>Current Temp: {(dataArray[21] / 100).toFixed(2)} (C)</p>
					<p className={classes.dataType}>Battery Temp: {(dataArray[24] / 100).toFixed(2)} (C)</p>
					<p className={classes.dataType}>BMS Firmware: {dataArray[25]}</p>
					<p className={classes.dataType}>Panel Current Draw: {(dataArray[19] / 1000).toFixed(2)} (A)</p>
					<p className={classes.dataType}>Panel Voltage: {(dataArray[20] / 100).toFixed(2)} (V)</p>
				</div>
			)
		}
		return message
	}

	const _formatDisplayMasterInfoMessage = (message) => {
		const dataArray = message.toString().slice(0, -1).split(",")
		const windValues = dataArray.slice(30, 82)
		const windGroups = []
		for (let i = 0; i < windValues.length; i++) {
			const last = windGroups[windGroups.length - 1]
			if (!last || last.length === 4) {
				windGroups.push([windValues[i]])
			} else {
				last.push(windValues[i])
			}
		}
		const firmwareVersion = dataArray[0]

		// Some angles are hardcoded alphabetical strings, others are numeric
		// It is only the latter we want to scale back down
		return (
			<div className={classes.dataFeedback}>
				<p className={classes.dataType}>Firmware Version: {firmwareVersion}</p>
				<p className={classes.dataType}>Max Nodes Defined : {dataArray[1]}</p>
				<p className={classes.dataType}>Fast Polling Available in Firmware : {dataArray[2]}</p>
				<p className={classes.dataType}>Fast-Polling Interval(minutes) : {dataArray[3]}</p>
				<p className={classes.dataType}>Zigbee PAN ID : {dataArray[4]}</p>
				<p className={classes.dataType}>Zigbee Channel : {dataArray[5]}</p>
				<p className={classes.dataType}>IP Address : {dataArray[6]}</p>
				<p className={classes.dataType}>Gateway : {dataArray[7]}</p>
				<p className={classes.dataType}>Network Mask : {dataArray[8]}</p>
				<p className={classes.dataType}>Mac Address : {dataArray[9]}</p>
				<p className={classes.dataType}>Master Parameter Version : {dataArray[10]}</p>
				<p className={classes.dataType}>Node Parameter Version save in Master : {dataArray[11]}</p>
				<p className={classes.dataType}>Node Image Version Saved in Flash : {dataArray[12]}</p>
				<p className={classes.dataType}>Timezone : {dataArray[13]}</p>
				{/* Flipped longitude and latitude because lat,long is the most common format */}
				<p className={classes.dataType}>Latitude : {parseFloat(dataArray[15]).toFixed(3)}</p>
				<p className={classes.dataType}>Longitude : {parseFloat(dataArray[14]).toFixed(3)}</p>
				<p className={classes.dataType}>Elevation : {dataArray[16]}</p>
				<p className={classes.dataType}>Pressure : {dataArray[17]}</p>
				<p className={classes.dataType}>Temperature : {dataArray[18]}</p>
				<p className={classes.dataType}>Row Spacing : {dataArray[19]}</p>
				<p className={classes.dataType}>Panel Length : {dataArray[20]}</p>
				<p className={classes.dataType}>East Angle Limit : {(dataArray[21] / 100).toFixed(2)}°</p>
				<p className={classes.dataType}>West Angle Limit : {(dataArray[22] / 100).toFixed(2)}°</p>
				<p className={classes.dataType}>Overnight Stow Angle : {(dataArray[23] / 100).toFixed(2)}°</p>
				<p className={classes.dataType}>Backtracking : {dataArray[24]}</p>
				<p className={classes.dataType}>
					System Safety Stow/Strongest Wind Angle : {(dataArray[25] / 100).toFixed(2)}°
				</p>
				<p className={classes.dataType}>Snow Stow Angle : {(dataArray[26] / 100).toFixed(2)}°</p>
				<p className={classes.dataType}>Slope : {dataArray[27]}</p>
				<p className={classes.dataType}>Plane Inclination : {dataArray[28]}</p>
				<p className={classes.dataType}>Slope Direction : {dataArray[29]}</p>
				<table>
					<tbody>
						<tr>
							<th>Level</th>
							<th>East Angle</th>
							<th>West Angle</th>
							<th>Speed (mph)</th>
							<th>Restore Time (minutes)</th>
						</tr>
						{windGroups.map((group, i) => {
							return (
								<tr key={i}>
									<th style={{ fontWeight: "normal" }}>{i}</th>
									<th style={{ fontWeight: "normal" }}>{group[0]}</th>
									<th style={{ fontWeight: "normal" }}>{group[1]}</th>
									<th style={{ fontWeight: "normal" }}>{group[2]}</th>
									<th style={{ fontWeight: "normal" }}>{group[3]}</th>
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		)
	}

	const _readNodeParamsFromNodeMessage = (message) => {
		const dataArray = message.toString().slice(0, -1).split(",")
		const [
			lowBatteryLimit,
			criticalBatteryLimit,
			highCurrentLimit,
			hardwareCurrentLimit,
			overcurrentTries,
			overcurrentTime,
			motorOnTime,
			motorOffTime,
			rampOnTime,
			rampDownPropCons,
			motorOnTimeStow,
			motorOffTimeStow,
			mechLimitEast,
			mechLimitWest,
			angleTolerance,
			motionTolerance,
			noMotionTime,
			motionFailures,
			arrrStatusIntervalMinute,
			otaTimeout,
			overvoltageThreshold,
			lowBatteryStow,
			strongestAngle,
			waitTimeMotorOff,
			waitTimeChargerOff,
			hotTempHiLimit,
			hotTempLowLimit,
			coldTempHiLimit,
			coldTempLowLimit,
			directionTolerance,
			startTolerance,
			startToleranceAntishade,
			directionTime,
			motorMinSpeed,
			limitFailures,
			limitTolerance,
			directionFailures,
			estopDisableTime,
			lowBattThresholdDuringMotion,
		] = dataArray
		const values = {
			lowBatteryLimit,
			criticalBatteryLimit,
			highCurrentLimit,
			hardwareCurrentLimit,
			overcurrentTries,
			overcurrentTime,
			motorOnTime,
			motorOffTime,
			rampOnTime,
			rampDownPropCons,
			motorOnTimeStow,
			motorOffTimeStow,
			mechLimitEast,
			mechLimitWest,
			angleTolerance,
			motionTolerance,
			noMotionTime,
			motionFailures,
			arrrStatusIntervalMinute,
			otaTimeout,
			overvoltageThreshold,
			lowBatteryStow,
			strongestAngle,
			waitTimeMotorOff,
			waitTimeChargerOff,
			hotTempHiLimit,
			hotTempLowLimit,
			coldTempHiLimit,
			coldTempLowLimit,
			directionTolerance,
			startTolerance,
			startToleranceAntishade,
			directionTime,
			motorMinSpeed,
			limitFailures,
			limitTolerance,
			directionFailures,
			estopDisableTime,
			lowBattThresholdDuringMotion,
		}
		return (
			<div className={classes.dataFeedback}>
				{Object.keys(values).map((paramId) => {
					if (!nodeParameters[paramId]) {
						return (
							<p key={paramId} className={classes.dataType}>
								{paramId + " (unsupported)"}: {values[paramId]}
							</p>
						)
					}
					return (
						<p key={paramId} className={classes.dataType}>
							{paramId}: {values[paramId]} {nodeParameters[paramId].unit ? `(${nodeParameters[paramId].unit})` : null}
						</p>
					)
				})}
			</div>
		)
	}

	const formatFeedbackMessage = (message, status) => {
		if (status !== "success") return message

		const commandName = (commandLookup[command] || {}).value

		switch (commandName) {
			case "request-node-realtime-status":
				return _formatRequestNodeRealtimeStatusMessage(message)
			case "display-master-info":
				return _formatDisplayMasterInfoMessage(message)
			case "read-node-params-from-node":
				return _readNodeParamsFromNodeMessage(message)
			default:
				return message
		}
	}

	const issueCommand = async () => {
		const allFeedback = {}

		const feedbackSubs = selectedMasters
			.map((mLocId) => {
				const mId = masterDetails[mLocId]["mId"]
				const name = (commandLookup[command] || {}).value
				if (!name || !mId) return null

				const subscription = API.graphql(
					graphqlOperation(subscribeCommandFeedbackGql, {
						mId,
						name,
					}),
				).subscribe({
					next: (result) => {
						const data = (result["value"] || {})["data"] || {}
						const receivedFeedback = data["subscribeCommandFeedback"]
						if (receivedFeedback) {
							const { status, message } = receivedFeedback
							setStatus({
								type: "received",
							})
							if (status === "fail" || status === "success") {
								setTimeout(() => setStatus({}), 2000)
							}
							allFeedback[mLocId] = allFeedback[mLocId] || []
							const formattedMessage = formatFeedbackMessage(message, status)
							allFeedback[mLocId].push(formattedMessage)
							setFeedback(allFeedback)
						}
					},
					error: (err) => {
						console.error("SUB ERROR:", err)
					},
				})
				// console.log(`subscribed to ${mId} > ${name}`)
				return subscription.unsubscribe.bind(subscription)
			})
			.filter((sub) => sub !== null)
		setSubscriptions(feedbackSubs)
		setStatus({
			type: "sending",
		})
		setFeedback({})
		const argList = Object.keys(args).map((argName) => {
			const details = commandParams[deviceType][argName]
			let formatValue

			if (details) {
				formatValue = details.formatValue
			}

			const rawValue = args[argName]
			const formattedValue = formatValue ? formatValue(rawValue) : rawValue

			return {
				key: argName,
				value: formattedValue,
			}
		})

		if (deviceDetailsType === "node") {
			argList.push({ key: "nId", value: nId })
		}

		const commandValue = commandLookup[command].value

		const requests = selectedMasters.map((mLocId) => {
			const thisMasterArgList = [...argList]
			const thisMasterDetails = masterDetails[mLocId]

			if (
				commandValue === "send-node-params-batch" ||
				commandValue === "send-master-params-batch" ||
				commandValue === "node-replacement-batch"
			) {
				const { nodeParamId, spaParamId } = thisMasterDetails

				if (commandValue === "send-node-params-batch" && !nodeParamId) {
					console.error("No node param id for master", mLocId)
					return
				}
				if (commandValue === "send-master-params-batch" && !spaParamId) {
					console.error("No spa param id for master", mLocId)
					return
				}

				if (!nodeParamId && !spaParamId) {
					console.error("No node or spa param id for master", mLocId)
					return
				}

				if (nodeParamId) {
					thisMasterArgList.push({
						key: "nodeParamId",
						value: nodeParamId,
					})
				}

				if (spaParamId) {
					thisMasterArgList.push({
						key: "spaParamId",
						value: spaParamId,
					})
				}
			}

			const commandRequest = {
				layoutId,
				layoutName,
				name: commandValue,
				args: thisMasterArgList,
				userId,
				username,
				mLocId,
				mId: thisMasterDetails["mId"],
				masterName: thisMasterDetails["name"],
			}
			return API.graphql(
				graphqlOperation(createCommandRequest, {
					input: commandRequest,
				}),
			)
		})

		try {
			const results = await Promise.all(requests.filter((r) => r))
			console.log(results) // TODO: add send success to feedback
		} catch (err) {
			console.error(err)
			setStatus({
				type: "error",
				message: err,
			})
		}
		setTimeout(() => {
			setStatus((currStatus) => {
				if (currStatus.type === "sending") {
					setTimeout(() => setStatus({}), 2000)
					return {
						type: "error",
						message: "Timeout",
					}
				} else if (currStatus.type !== "sending") {
					return currStatus
				}
			})
		}, 15000)
	}

	const submit = (e) => {
		e.preventDefault()
		// noinspection JSIgnoredPromiseFromCall
		issueCommand()
	}

	const handleParamChange = (paramName) => (event) => {
		setArgs({ ...args, [paramName]: event.target.value })
	}

	const isRunDisabled = () => {
		if (selectedMasters.length === 0) {
			return true
		}
		if (!command) {
			return true
		}

		const validByDefault = ["checkBox"]

		if (["node", "master"].includes(deviceDetailsType)) {
			const parameters = (commandLookup[command] || {})["paramSelectDependencies"] || []

			const requiredParameters = parameters
				.map((paramName) => {
					const details = commandParams[deviceType][paramName]
					if (!validByDefault.includes(details["type"])) {
						return details.paramName || paramName
					}
				})
				// Filter out null values
				.filter((param) => !!param)

			for (const requiredParameter of requiredParameters) {
				if (requiredParameter === "nId" && deviceDetailsType === "node") {
					// Do not require nId parameter if this is a node. nId will be added automatically.
					continue
				}
				if (!Object.prototype.hasOwnProperty.call(args, requiredParameter)) {
					return true
				}
			}

			// Check if all filled in values are valid
			for (const [argName, value] of Object.entries(args)) {
				const details = commandParams[deviceType][argName]
				if (details === undefined) {
					// This is not a manual input, but a hardcoded value. Always valid.
					continue
				}
				if (details.isValid && !details.isValid(value)) {
					return true
				}
			}
		}

		return status.type !== undefined
	}

	const handleCheckBoxChange = (paramName, e) => {
		// NOTE: The IoT client and/or firmware will not accept true/false.
		//  True must be sent as 1, and false must be sent as 0.
		const value = e.target.checked ? 1 : 0

		setCheckedOptions((currCheckedOptions) => {
			if (value) {
				if (!currCheckedOptions.includes(paramName)) {
					return [...currCheckedOptions, paramName]
				}
			} else {
				return currCheckedOptions.filter((param) => param !== paramName)
			}
		})
		setArgs((prevArgs) => ({ ...prevArgs, [paramName]: value }))
	}

	const masterInfo = {}
	Object.keys(masterDetails).forEach((mLocId) => {
		masterInfo[mLocId] = masterDetails[mLocId].name
	})

	const renderParameterControlOld = (commandParamDetails) => {
		let value = args[commandParamDetails["paramName"]]
		if (value === undefined) value = ""
		let isValid = Object.prototype.hasOwnProperty.call(args, commandParamDetails.paramName)
		if (isValid) isValid = commandParamDetails.isValid ? commandParamDetails.isValid(value) : true

		switch (commandParamDetails.type) {
			case "input":
				return (
					<div key={commandParamDetails.paramName}>
						<FormControl error={false} {...commandParamDetails.props}>
							<TextField
								id={commandParamDetails.paramName}
								key={commandParamDetails.paramName}
								error={!isValid}
								onChange={handleParamChange(
									commandParamDetails.paramName,
									commandParamDetails.formatValue,
									commandParamDetails.isValid,
								)}
								label={commandParamDetails.label}
								helperText={commandParamDetails.helperText || null}
								margin="normal"
								variant="outlined"
								value={value}
							/>
						</FormControl>
					</div>
				)
			case "checkBox":
				return (
					<div className={classes.paramSelect} key={commandParamDetails.paramName}>
						<Checkbox
							checked={checkedOptions.includes(commandParamDetails.paramName)}
							onChange={(e) => {
								handleCheckBoxChange(commandParamDetails.paramName, e)
							}}
							color="primary"
							id={commandParamDetails.paramName}
							key={commandParamDetails.paramName}
							style={{ padding: "0", margin: "4px 0 4px 0" }}
						/>
						<div>{commandParamDetails.label} </div>
					</div>
				)
		}
	}

	const setValue = (paramName) => {
		return (value) => {
			const newArgs = { ...args, [paramName]: value }
			setArgs(newArgs)
		}
	}

	const renderParameterControl = (commandParamDetails) => {
		if (commandParamDetails.type === undefined) {
			throw new Error(`type is required in command param details for ${commandParamDetails.paramName}`)
		}
		if (typeof commandParamDetails.type === "string") {
			return renderParameterControlOld(commandParamDetails)
		}
		const props = {
			...(commandParamDetails.props || {}),
			key: commandParamDetails.paramName,
			setValue: setValue(commandParamDetails.paramName),
			value: args[commandParamDetails.paramName] || null,
		}
		return React.createElement(commandParamDetails.type, props)
	}

	const feedbackComponents = Object.keys(feedback)
		.sort((a, b) => {
			const aName = masterDetails[a].name
			const bName = masterDetails[b].name
			if (aName.length === bName.length) {
				return aName.localeCompare(bName)
			}
			return aName.length - bName.length
		})
		.map((uuid) => {
			const feedbackEntries = feedback[uuid]
			return <Feedback key={uuid} feedbackEntries={feedbackEntries} masterName={masterDetails[uuid]["name"]} />
		})

	return (
		<div className={classes.root}>
			<div className={classes.input}>
				<form ref={myForm} onSubmit={submit}>
					{deviceDetailsType === "master" ? (
						<div>
							<MasterSelect
								key={layoutId}
								componentClass={classes.formControl}
								layoutId={layoutId}
								selected={selectedMasters}
								setSelected={setSelectedMasters}
								masterInfo={masterInfo}
							/>
						</div>
					) : null}
					<div>
						<InputLabel ref={inputLabel} htmlFor="command-select" style={{ margin: "15px" }}>
							Select Command
						</InputLabel>
						<SelectSingle
							items={commands}
							setValue={(identifier) => handleCommandChange(identifier)}
							value={command}
							pluralName={"commands"}
						/>
					</div>
					{((commandLookup[command] || {})["paramSelectDependencies"] || []).map((depName) => {
						if (depName === "nId" && deviceDetailsType === "node") {
							return null
						} else {
							const commandParamDetails = commandParams[deviceType][depName]
							return renderParameterControl(commandParamDetails)
						}
					})}
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={isRunDisabled()}
						className={classes.formControl}
					>
						{status.type === undefined ? "Run Command" : `${status.type.toUpperCase()}`}
					</Button>
				</form>
			</div>
			<div className={classes.feedback}>{feedbackComponents}</div>
		</div>
	)
}

Controls.propTypes = {
	privileges: PropTypes.number.isRequired,
	masterDetails: PropTypes.object.isRequired,
	mLocId: PropTypes.string.isRequired,
	layoutId: PropTypes.string.isRequired,
	layoutName: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	deviceDetailsType: PropTypes.string.isRequired,
	nId: PropTypes.string,
	deviceType: PropTypes.string.isRequired,
	nodeFwVersion: PropTypes.string.isRequired,
}

export default Controls

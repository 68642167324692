import { connect } from "react-redux"
import { compose } from "redux"
import { withRouter } from "react-router-dom"
import { withSnackbar } from "notistack"
import ConfigStepper from "./ConfigStepper"
import { resetConfigStepper } from "store/actions/reduxActions"
import { updateNodeLocations, updateMasterDetails, updateLayoutDetails } from "store/actions/setters"
import { createSelector } from "reselect"

const getLocations = (state, _props) => state.configLocations

// TODO: Reuse selectors globally
const getUnassignedNodeCount = createSelector([getLocations], (configLocations) => {
	let count = 0
	for (let i = 0; i < configLocations.length; i++) {
		if (!Object.prototype.hasOwnProperty.call(configLocations[i], "mLocId")) {
			count++
		}
	}
	return count
})

const mapStateToProps = (state, props) => {
	const params = new URLSearchParams(props.location.search)
	const activeStepKey = params.get("step")
	return {
		activeStepKey: activeStepKey ? activeStepKey : "layout-details",
		configLocations: state.configLocations,
		configNodeTranslations: state.configNodeTranslations,
		configMasterAreas: state.configMasterAreas,
		unassignedNodeCount: getUnassignedNodeCount(state, props),
	} // TODO: masterAreas: [get from state.masterDetails[n].area] for automatic assignment when coordinates are added
}

const mapDispatchToProps = (dispatch, _props) => ({
	setupConfigStepper: () => dispatch(resetConfigStepper()),
	updateNodeLocationsByMaster: (mLocId, layoutId, nLocs, nIds) =>
		dispatch(updateNodeLocations(mLocId, layoutId, { nLocs, nIds })),
	updateMasterArea: (mLocId, layoutId, area) => dispatch(updateMasterDetails(mLocId, layoutId, { area })),
	updateMarkerRadius: (layoutId, markerRadius) => dispatch(updateLayoutDetails(layoutId, { markerRadius })),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withSnackbar)(ConfigStepper)

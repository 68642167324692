import { connect } from "react-redux"
import NodeMarker from "./NodeMarker"
import { getTranslatedCenter } from "utils"

const mapStateToProps = (state, props) => ({
	center: getTranslatedCenter(state.configLocations[props.index], state.configNodeTranslations),
	radius: state.configNodeTranslations.markerRadius || 0.5,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NodeMarker)

import React, { Children, useMemo } from "react"
import PropTypes from "prop-types"
import { Circle, Marker, Polygon, Rectangle } from "react-leaflet"
import { divIcon } from "leaflet"
import ReactDOMServer from "react-dom/server"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(() => ({
	componentsScanned: {
		width: "100%",
		height: "25px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		border: "solid thin #fff",
		textAlign: "center",
		fontSize: "15px",
		fontWeight: "bold",
	},
}))

const CustomMarker = ({ position, children, ...otherProps }) => {
	const icon = divIcon({
		html: ReactDOMServer.renderToString(Children.only(children)),
		//Added to override the default marker styling from divIcon
		className: "",
	})

	return <Marker position={position} icon={icon} {...otherProps}></Marker>
}

const MasterMarker = ({
	isDeviceIdAssignment,
	componentsScanned,
	selected,
	center,
	radius,
	onClick: handleClick,
	onMouseOver,
	onMouseOut,
	markerColor,
	markerFill,
	timestamp,
}) => {
	if (center.length !== 2) return null
	const styles = useStyles()
	const twoHoursInMilliseconds = 2 * 60 * 60 * 1000
	//Checking if master data is older than 2 hours
	const isGreaterTwoHours = timestamp && Date.now() - timestamp * 1000 > twoHoursInMilliseconds
	const bounds = [
		[center[0] - radius, center[1] - radius],
		[center[0] + radius, center[1] + radius],
	]
	const y = center[0] + radius * -4
	const x = center[1]
	const masterComponentsScannedPosition = [center[0] - 15, center[1]]

	const numComponentsScanned = useMemo(() => {
		if (!componentsScanned) return 0
		return Object.keys(componentsScanned).length
	}, [componentsScanned])

	const dynamicStyles = useMemo(() => {
		switch (numComponentsScanned) {
			case 1:
				return {
					background: "silver",
					color: "#000",
				}
			case 2:
				return {
					background: "grey",
					color: "#000",
				}
			case 3:
				return {
					background: "black",
					color: "#fff",
				}
			case 4:
				return {
					background: "green",
					color: "#fff",
				}
			default:
				return {
					background: "yellow",
					color: "#000",
				}
		}
	}, [numComponentsScanned])

	return (
		<>
			<Rectangle
				key={`${center[0]}-${center[1]}-${selected}`}
				bounds={bounds}
				color={markerColor}
				fillColor={markerFill}
				onClick={handleClick}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
			/>

			{isDeviceIdAssignment && numComponentsScanned > 0 && (
				<CustomMarker position={masterComponentsScannedPosition}>
					<div
						className={styles.componentsScanned}
						style={{ background: dynamicStyles.backgroundColor, color: dynamicStyles.color }}
					>
						<p>{componentsScanned ? numComponentsScanned : 0}</p>
					</div>
				</CustomMarker>
			)}

			{isGreaterTwoHours && !isDeviceIdAssignment && (
				<ExclamationIcon x={x} y={y} width={radius / 1} height={radius * 5} />
			)}
		</>
	)
}

MasterMarker.propTypes = {
	classes: PropTypes.object,
	center: PropTypes.array.isRequired,
	radius: PropTypes.number.isRequired,
	onClick: PropTypes.func,
	onMouseOver: PropTypes.func,
	onMouseOut: PropTypes.func,
	markerColor: PropTypes.string.isRequired,
	markerFill: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	timestamp: PropTypes.number,
	isDeviceIdAssignment: PropTypes.bool,
	componentsScanned: PropTypes.object,
}

CustomMarker.propTypes = {
	position: PropTypes.array.isRequired,
	children: PropTypes.element.isRequired,
}

export default MasterMarker

const ExclamationIcon = ({ width = 4, height = 10, x, y }) => {
	const markerPosition = [
		[y, x],
		[y, x + width],
		[y - height, x + width * 0.75],
		[y - height, x + width * 0.25],
		[y, x],
	]
	const circleCenter = [y - height - width * 3, x + width / 2]
	return (
		<>
			<Polygon color="#FFBE40" fillOpacity="1" fillColor="#FFBE40" positions={markerPosition} />
			<Circle color="#FFBE40" fillOpacity="1" fillColor="#FFBE40" center={circleCenter} radius={width} />
		</>
	)
}
ExclamationIcon.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	x: PropTypes.number,
	y: PropTypes.number,
}

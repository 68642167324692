import { connect } from "react-redux"
import format from "date-fns/format"
import DataTable from "./DataTable"
import { setDataTableIsOpen } from "store/actions/reduxActions"
import { withRouter } from "react-router"
import { compose } from "redux"
import { getMasterLocIds } from "utils/url"
import { alarmEnums, getAlarmsString, getFlagsString } from "constants/statusBits"
import { getRecentMasterDataFromMLocId } from "../../../../../utils/stateSelectors"

const getUrlTimestamp = (urlSearch = "") => {
	const params = new URLSearchParams(urlSearch)
	return parseInt(params.get("ts"), 10) || undefined
}

const getDeviceType = (state, mLocId) => {
	return (state.masterDetails[mLocId] || {}).deviceType
}

export const getNoDataAvailableNLocIds = (state, mLocId, timestamp) => {
	const nodeDetails = state.nodeDetails[mLocId] || {}
	const nodeData = state.nodeData[`${mLocId}#${timestamp}`] || {}
	const nodeDataNLocIds = Object.keys(nodeData)

	const noDataAvailableNLocIds = []
	Object.keys(nodeDetails).forEach((nLocId) => {
		if (!nodeDataNLocIds.includes(nLocId)) {
			noDataAvailableNLocIds.push(nLocId)
		}
	})
	return noDataAvailableNLocIds
}

const getAngleDeviationNLocIds = (state, mLocId, timestamp) => {
	const angleBenchmark = 25 // TODO: make variable
	const thresholdAngle = 500 // TODO: make variable

	const nodeData = state.nodeData[`${mLocId}#${timestamp}`] || {}

	const angleDeviationNLocIds = []
	Object.keys(nodeData).forEach((nLocId) => {
		const { anglePanel } = nodeData[nLocId]
		if (anglePanel) {
			// TODO: filter out no data available from the master or node
			if (Math.abs(anglePanel - angleBenchmark) > thresholdAngle) {
				angleDeviationNLocIds.push(nLocId)
			}
		}
	})
	return angleDeviationNLocIds
}

function createData(props, state) {
	let mLocIds = getMasterLocIds(props.location.search)
	const urlSearch = (props.location || {}).search
	let rows = []

	mLocIds.forEach((mLocId) => {
		const nodeDetails = state.nodeDetails[mLocId] || {}
		const timestamp = getUrlTimestamp(urlSearch) || state.nodeDataActiveTimestamp[mLocId]
		const noDataAvailableIds = getNoDataAvailableNLocIds(state, mLocId, timestamp)
		const angleDeviationIds = getAngleDeviationNLocIds(state, mLocId, timestamp)
		const duplicateNodeIds = props.duplicateNodeIdsByMaster[mLocId]

		const masterName = (state.masterDetails[mLocId] || {})["name"]
		const nodeDataByMaster = state.nodeData[`${mLocId}#${timestamp}`] || {}
		let deviceType

		Object.keys(nodeDetails).forEach((nLocId) => {
			deviceType = getDeviceType(state, mLocId) // TODO: only handles display of single device type at a time
			const angleDeviation = angleDeviationIds.includes(nLocId)
			const noDataAvailable = noDataAvailableIds.includes(nLocId)
			const duplicateNodeId = duplicateNodeIds.includes(
				state.nIdsPendingUpload[`${mLocId}#${nLocId}`] || nodeDetails[nLocId].nId,
			)
			const deviceAlarmEmuns = alarmEnums[deviceType] || {}

			const duplicateNodeIdAlarm = (duplicateNodeId && deviceAlarmEmuns.DUPLICATE_NODEID) || 0
			const noDataAvailableServerAlarm = (noDataAvailable && deviceAlarmEmuns.NO_DATA_IN_DATABASE) || 0
			const angleDeviationAlarm = (angleDeviation && deviceAlarmEmuns.ANGLE_DEVIATION) || 0
			const masterData = getRecentMasterDataFromMLocId(state, props, mLocId) || {}
			const nodeFwVersion = masterData["fwNode"] || masterData["fwMaster"] || "1.0.0"

			if (nodeFwVersion === "1.0.0") console.error("Node/master firmware version not available")

			let nodeAlarms
			let nodeFlags
			let nodeData = {}

			if (state.nodeDataStatus[mLocId] === "resolved" && nodeDataByMaster[nLocId]) {
				const { alarms, flags, timestamp, nId: _, ...data } = nodeDataByMaster[nLocId]
				nodeAlarms = alarms | noDataAvailableServerAlarm | angleDeviationAlarm | duplicateNodeIdAlarm
				nodeFlags = flags
				const alarmString = getAlarmsString(state.user.privileges, deviceType)(nodeAlarms)
				const flagString = getFlagsString(state.user.privileges, deviceType)(
					BigInt(`0x${nodeFlags}`).toString(16),
					nodeFwVersion,
				)

				const time = format(new Date(timestamp * 1000), "yyyy-MM-dd HH:mm:ss")
				Object.keys(data).forEach((val) => (nodeData[`${val}`] = data[val]))
				nodeData["timestamp"] = time
				nodeData["alarms"] = alarmString
				nodeData["flags"] = flagString
			}
			const { nId, fromSouth, rowNum } = nodeDetails[nLocId]
			nodeData["nId"] = `0x${nId}`
			nodeData["master"] = masterName
			nodeData["mLocId"] = mLocId
			nodeData["nLocId"] = nLocId
			nodeData["fromSouth"] = fromSouth
			nodeData["rowNum"] = rowNum
			rows.push(nodeData)
		})
	})
	return rows
}

export const columns = [
	{
		width: 120,
		flexGrow: 1,
		label: "Node Id",
		dataKey: "nId",
		numeric: false,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Master",
		dataKey: "master",
		numeric: true,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "From South",
		dataKey: "fromSouth",
		numeric: true,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Row Number",
		dataKey: "rowNum",
		numeric: true,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Node Location",
		dataKey: "nLocId",
		numeric: true,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Alarms",
		dataKey: "alarms",
		numeric: true,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Flags",
		dataKey: "flags",
		numeric: true,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1.0,
		label: "Panel Angle",
		dataKey: "anglePanel",
		numeric: true,
		disableSort: false,
		default: true,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Angle Table Index",
		dataKey: "angleTableIndex",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Angle Target",
		dataKey: "angleTarget",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Battery Voltage",
		dataKey: "batteryVoltage",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Battery Voltage Charger Off",
		dataKey: "batteryVoltageChargerOff",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Charge Current",
		dataKey: "chargeCurrent",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Charge Status",
		dataKey: "chargeStatus",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Check-in Round",
		dataKey: "checkinRound",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "First Hop",
		dataKey: "firstHop",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Hop Count",
		dataKey: "hopCount",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Motor Current Average",
		dataKey: "motorCurrentAve",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Motor Current Peak",
		dataKey: "motorCurrentPeak",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Sleep Counter",
		dataKey: "sleepCounter",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Max Temperature",
		dataKey: "tempMax",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Min Temperature",
		dataKey: "tempMin",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Call & Response Duration",
		dataKey: "arrrDuration",
		numeric: true,
		disableSort: false,
	},
	{
		width: 120,
		flexGrow: 1,
		label: "Timestamp",
		dataKey: "timestamp",
		numeric: false,
		disableSort: false,
	},
]

const mapStateToProps = (state, props) => {
	return {
		rows: createData(props, state),
		columns,
		userPrivileges: state.user.privileges,
		masterDetails: state.masterDetails[props.layoutId] || {},
		dataTableIsOpen: state.dataTableIsOpen,
		fwNode: state.masterDetails[props.layoutId],
	}
}

const mapDispatchToProps = (dispatch, _props) => ({
	setDataTableIsOpen: (isOpen) => dispatch(setDataTableIsOpen(isOpen)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DataTable)

import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepButton from "@material-ui/core/StepButton"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import green from "@material-ui/core/colors/green"
import { Link } from "react-router-dom"

import CoordinateUploader from "./CoordinateUploader"
import CoordinateTranslator from "./CoordinateTranslator"
import CoordinateAssignment from "./CoordinateAssignment"
import MasterCoordinates from "./MasterCoordinates"
import { getTranslatedCenter } from "utils"

const RequiredLabel = () => {
	// TODO: This is a temporary measure. Make all steps optional.
	return <span style={{ color: "#e59b00", fontWeight: "bold", float: "left" }}>required</span>
}

const styles = (theme) => ({
	root: {
		overflowY: "auto",
		flex: 1,
		padding: theme.spacing(1),
	},
	button: {
		margin: theme.spacing(1),
	},
	finishContainer: {
		display: "flex",
		flexDirection: "column",
		margin: "auto",
	},
	finishButton: {
		width: "50%",
		margin: "auto",
		marginTop: theme.spacing(1),
	},
	stepWrapper: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	stepContent: {
		margin: theme.spacing(1),
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	saveButton: {
		width: "100%",
	},
	wrapper: {
		width: "50%",
		margin: "auto",
		position: "relative",
	},
	currentStep: {
		cursor: "default",
		animation: "none",
	},
	futureStep: {
		opacity: "0.5",
		cursor: "default",
		animation: "none",
	},
})

const stepKeys = ["layout-details", "upload-coordinates", "position-coordinates", "master-coordinates", "master-areas"]
const steps = [
	{ label: "Layout Details", required: false },
	{ label: "Upload Coordinates", required: true },
	{ label: "Position Coordinates", required: false },
	{ label: "Master Coordinates", required: true },
	{ label: "Master Areas", required: true },
]

const getStepContent = (index, props) => {
	const { layoutId, setCanContinue } = props

	switch (index) {
		case 0:
			return (
				<div className={props.classes.stepWrapper}>
					<Button
						variant="contained"
						color="primary"
						className={props.classes.stepContent}
						component={Link}
						to={{
							search: `?step=layout-details&modify-layout=${props.layoutId}`,
						}}
					>
						Details
					</Button>
				</div>
			)
		case 1:
			return <CoordinateUploader layoutId={layoutId} setCanContinue={setCanContinue} />
		case 2:
			return <CoordinateTranslator />
		case 3:
			return <MasterCoordinates layoutId={layoutId} setCanContinue={setCanContinue} />
		case 4:
			return <CoordinateAssignment layoutId={layoutId} setCanContinue={setCanContinue} />
		default:
			return <div>Stepper Error!</div>
	}
}

const ConfigStepper = ({
	classes,
	layoutId,
	activeStepKey,
	history,
	configLocations,
	configNodeTranslations,
	configMasterAreas,
	unassignedNodeCount,
	setupConfigStepper,
	updateNodeLocationsByMaster,
	updateMasterArea,
	updateMarkerRadius,
	enqueueSnackbar,
}) => {
	if (layoutId === undefined) {
		throw new Error("No layoutId specified")
	}

	const [pendingResponse, setPendingResponse] = useState(false)
	const [canContinue, _setCanContinue] = useState(true)
	const activeStep = stepKeys.indexOf(activeStepKey)

	const setCanContinue = useCallback(
		(value) => {
			// Only rerender if value is different. This prevents an infinite render loop when this callback is used inside
			// a child component's useEffect hook. Without it, the child's hook would cause a render, which in turn would
			// trigger the hook, which would trigger a render.
			if (value !== canContinue) {
				_setCanContinue(value)
			}
		},
		[canContinue, _setCanContinue],
	)

	const getStepHandler = useCallback(
		(step) => () => {
			let newStep

			switch (step) {
				case "next":
					newStep = activeStep + 1
					break
				case "back":
					newStep = activeStep - 1
					break
				case "reset":
					newStep = 0
					break
				default:
					newStep = step
					break
			}

			history.push({ search: "?step=" + stepKeys[newStep] })

			// NOTE: `true` is the safe default.
			const newCanContinue =
				!isFinite(newStep) || // is not a number (see default case)
				newStep < 0 || // is less than 0 (invalid or reset)
				newStep >= stepKeys.length || // out of bounds
				!steps[newStep].required // selected step is not required
			setCanContinue(newCanContinue)
		},
		[setCanContinue, activeStep, history],
	)

	if (activeStep === -1) {
		getStepHandler("reset")() // Get and call handler for 'reset'
	}

	const handleFinalSave = useCallback(() => {
		setPendingResponse(true)
		const nbUnassigned = unassignedNodeCount ? unassignedNodeCount : 0

		if (nbUnassigned > 0 && !confirm(`${unassignedNodeCount} unassigned nodes will be lost. Continue?`)) {
			setPendingResponse(false)
			return
		}

		const successes = []

		delete configMasterAreas.layoutId
		const { ...masterAreas } = configMasterAreas

		const { markerRadius } = configNodeTranslations
		successes.push(updateMarkerRadius(layoutId, markerRadius))
		Object.keys(masterAreas).forEach((mLocId) => {
			successes.push(updateMasterArea(mLocId, layoutId, masterAreas[mLocId]))
		})
		// Assign nodes to master location
		const tempLocsByMaster = {}
		for (let i = 0; i < configLocations.length; i++) {
			const { mLocId, ...otherValues } = configLocations[i]

			// TODO: May be unnecessary
			delete otherValues.nLocId
			delete otherValues.pendingSync

			if (mLocId) {
				if (!Object.prototype.hasOwnProperty.call(tempLocsByMaster, mLocId)) {
					tempLocsByMaster[mLocId] = []
				}
				const transLocs = getTranslatedCenter(otherValues, configNodeTranslations)
				otherValues["xLoc"] = transLocs[1]
				otherValues["yLoc"] = transLocs[0]
				tempLocsByMaster[mLocId].push(otherValues)
			}
		}
		// Assign node locations
		const locationsByMaster = {}
		Object.keys(tempLocsByMaster).forEach((mLocId) => {
			const sortedNLocs = tempLocsByMaster[mLocId].sort((a, b) => a.distance - b.distance)
			locationsByMaster[mLocId] = sortedNLocs.map((nLoc, index) => ({
				...nLoc,
				index,
			}))
		})
		Object.keys(locationsByMaster).forEach((mLocId) => {
			const nLocs = []
			const nIds = []
			locationsByMaster[mLocId].forEach((location) => {
				const { nId, index, ...otherDetails } = location
				if (nId) {
					nIds.push({
						index: index.toString().padStart(3, "0"),
						id: nId,
					})
				}
				nLocs.push({
					index,
					...otherDetails,
				})
			})
			console.info("Adding nodes to master:", nLocs, nIds)
			successes.push(updateNodeLocationsByMaster(mLocId, layoutId, nLocs, nIds))
		})
		Promise.all(successes)
			.then(() => {
				console.log("save successful")
				enqueueSnackbar("Save Successful", {
					variant: "success",
				})
				history.push({
					pathname: "/layouts",
					search: "?view=list",
				})
			})
			.catch((e) => {
				enqueueSnackbar("Save Not Successful!", {
					variant: "error",
				})
				console.error("save not successful!", e)
				setPendingResponse(false)
			})
	}, [
		configLocations,
		configMasterAreas,
		configNodeTranslations,
		enqueueSnackbar,
		history,
		layoutId,
		unassignedNodeCount,
		updateMarkerRadius,
		updateMasterArea,
		updateNodeLocationsByMaster,
	])

	useEffect(() => {
		setupConfigStepper()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // No deps; run only once.

	const getStepClickHandler = (index) => {
		// You can go backwards when clicking on a step label.
		if (index < activeStep) return getStepHandler(index)
		// If the next button is enabled, and the clicked step is the next step, allow it.
		if (index === activeStep + 1 && canContinue) return getStepHandler(index)
		// No handler for clicking on the current step. TODO: Consider collapsing.
		if (index === activeStep) return null
		// Otherwise, show snackbar:
		return () => enqueueSnackbar("You must complete all prior steps.")
	}

	const getStepClassName = (index) => {
		// No special styles (indicate click allowed).
		if (index < activeStep) return ""
		// Same step; indicate click disallowed, and indicate current step.
		if (index === activeStep) return classes.currentStep
		// Next step; indicate click allowed if the next button is enabled.
		if (index === activeStep + 1 && canContinue) return ""
		// At least two steps forward (or next button is disabled); indicate click disallowed.
		return classes.futureStep
	}

	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep} orientation="vertical" nonLinear>
				{steps.map((step, index) => (
					<Step key={step.label}>
						<StepButton onClick={getStepClickHandler(index)} className={getStepClassName(index)}>
							<StepLabel>{step.label}</StepLabel>
							{step.required && index === activeStep ? <RequiredLabel /> : null}
						</StepButton>
						<StepContent>
							{getStepContent(index, { classes, layoutId, setCanContinue })}
							<div className={classes.actionsContainer}>
								{activeStep === 0 ? null : (
									<Button onClick={getStepHandler("back")} className={classes.button}>
										Back
									</Button>
								)}
								{activeStep === steps.length - 1 ? null : (
									<Button
										onClick={getStepHandler("next")}
										variant="contained"
										color="secondary"
										className={classes.button}
										disabled={!canContinue}
									>
										Next
									</Button>
								)}
							</div>
						</StepContent>
					</Step>
				))}
			</Stepper>
			<div className={classes.finishContainer}>
				<div className={classes.wrapper}>
					<Button
						variant="contained"
						color="primary"
						disabled={pendingResponse || activeStep < steps.length - 1 || !canContinue}
						onClick={handleFinalSave}
						className={classes.saveButton}
					>
						Save & Close
					</Button>
					{pendingResponse && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
				<Button
					color="primary"
					className={classes.finishButton}
					component={Link}
					to={{
						pathname: "/layouts",
						search: "?view=list",
					}}
				>
					{pendingResponse ? "Abort & Close" : "Close"}
				</Button>
			</div>
		</div>
	)
}

ConfigStepper.propTypes = {
	classes: PropTypes.object.isRequired,
	layoutId: PropTypes.string.isRequired,
	activeStepKey: PropTypes.string,
	history: PropTypes.object,
	configLocations: PropTypes.array.isRequired,
	configNodeTranslations: PropTypes.object.isRequired,
	configMasterAreas: PropTypes.object.isRequired,
	setupConfigStepper: PropTypes.func.isRequired,
	updateNodeLocationsByMaster: PropTypes.func.isRequired,
	updateMasterArea: PropTypes.func.isRequired,
	enqueueSnackbar: PropTypes.func.isRequired,
	updateMarkerRadius: PropTypes.func.isRequired,
	unassignedNodeCount: PropTypes.number.isRequired,
}

export default withStyles(styles)(ConfigStepper)

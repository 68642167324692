import { connect } from "react-redux"
import { withRouter } from "react-router"
import { withSnackbar } from "notistack"
import { compose } from "redux"
import SelectMasterCoordinates from "./SelectMasterCoordinates"
import { setConfigMasterCoordinates } from "store/actions/reduxActions"
import { getLayoutMasterIds } from "utils"
import { updateMasterDetails } from "store/actions/setters"

const mapStateToProps = (state, props) => {
	const { layoutId } = state.configMasterCoordinates
	const masters = []
	getLayoutMasterIds(state.layoutDetails, layoutId).forEach((mLocId) => {
		if (state.masterDetails.hasOwnProperty(mLocId)) {
			masters.push({
				mLocId,
				name: state.masterDetails[mLocId]["name"],
			})
		}
	})
	return {
		masters,
		layoutId,
		coordinates: state.configMasterCoordinates.coordinates,
	}
}

const mapDispatchToProps = (dispatch) => ({
	setConfigMasterCoordinates: (coordinates) => dispatch(setConfigMasterCoordinates(coordinates)),
	updateMasterCoordinates: (mLocId, layoutId, xLoc, yLoc) =>
		dispatch(updateMasterDetails(mLocId, layoutId, { xLoc, yLoc })),
})

export default compose(withSnackbar, withRouter, connect(mapStateToProps, mapDispatchToProps))(SelectMasterCoordinates)

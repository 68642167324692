import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import { Polygon, FeatureGroup } from "react-leaflet"
import { EditControl } from "react-leaflet-draw"
import classifyPoint from "robust-point-in-polygon"
import polylabel from "polylabel"
import { getTranslatedCenter } from "utils"
import DivIcon from "components/DivIcon"
import Typography from "@material-ui/core/Typography"

const styles = (theme) => ({
	label: {
		width: 90,
		textAlign: "center",
		opacity: 0.75,
		backgroundColor: "rgb(128, 128, 128, .5)",
		color: "white",
		transform: "translateX(-45px)",
		borderRadius: "15px",
	},
})

const assignNodesToMasters = (nodePoints, translations, masterAreas, masterLocations) => {
	const assignedNodes = []
	// let assignedCount = 0
	for (let nodeIndex = 0; nodeIndex < nodePoints.length; nodeIndex++) {
		let assigned = false
		const mLocIds = Object.keys(masterAreas)
		for (let masterIndex = 0; masterIndex < mLocIds.length; masterIndex++) {
			const mLocId = mLocIds[masterIndex]
			const area = masterAreas[mLocId]
			let distance = 9999
			if (masterLocations[mLocId]) {
				distance = Math.sqrt(
					Math.pow(masterLocations[mLocId][0] - nodePoints[nodeIndex]["yLoc"], 2) +
						Math.pow(masterLocations[mLocId][1] - nodePoints[nodeIndex]["xLoc"], 2),
				)
			}
			if (classifyPoint(area, getTranslatedCenter(nodePoints[nodeIndex], translations)) < 1) {
				assignedNodes.push({ ...nodePoints[nodeIndex], mLocId, distance })
				assigned = true
				// assignedCount++
				break
			}
		}
		if (!assigned) {
			const { mLocId, ...unassignedNode } = nodePoints[nodeIndex]
			assignedNodes.push(unassignedNode)
		}
	}

	return assignedNodes
}

const distanceBetweenPoints = (p1, p2) => {
	return Math.abs(Math.sqrt(Math.pow(p2["yLoc"] - p1["yLoc"], 2) + Math.pow(p2["xLoc"] - p1["xLoc"], 2)))
}

const sortByDistance = (point, pointsArray) => {
	return [...pointsArray].sort((a, b) => distanceBetweenPoints(point, a) - distanceBetweenPoints(point, b))
}

const getRowSpacing = (nodePoints) => {
	let rowSpacings = {}
	const numberOfNodes = nodePoints.length
	for (let i = 0; i < Math.min(numberOfNodes, 200); i++) {
		// A positive distance is required, even if there's only one node.
		let xDistance = 1
		if (numberOfNodes > 1) {
			xDistance = Math.abs(nodePoints[i].xLoc - sortByDistance(nodePoints[i], nodePoints)[1].xLoc)
		}
		if (Object.prototype.hasOwnProperty.call(rowSpacings, xDistance)) {
			rowSpacings[xDistance]++
		} else {
			rowSpacings[xDistance] = 1
		}
	}
	return Object.keys(rowSpacings).sort((a, b) => {
		return rowSpacings[b] - rowSpacings[a]
	})[0]
}

const assignRowNums = (rowSpacing, nodePoints) => {
	const halfRowSpacing = rowSpacing * 0.51
	let assignedNodes = []
	const nodesByMaster = {}
	for (let i = 0; i < nodePoints.length; i++) {
		if (nodesByMaster.hasOwnProperty(nodePoints[i]["mLocId"])) {
			nodesByMaster[nodePoints[i]["mLocId"]].push(nodePoints[i])
		} else {
			nodesByMaster[nodePoints[i]["mLocId"]] = [nodePoints[i]]
		}
	}
	Object.keys(nodesByMaster).forEach((mLocId) => {
		let sortedNodePoints = nodesByMaster[mLocId].sort(function (a, b) {
			const xDist = a["xLoc"] - b["xLoc"]
			if (xDist >= 0 && xDist < halfRowSpacing) {
				return a["yLoc"] - b["yLoc"]
			}
			return a["xLoc"] - b["xLoc"]
		})
		let prevNode = { ...sortedNodePoints[0], rowNum: 1, fromSouth: 1 }
		assignedNodes.push(prevNode)
		for (let i = 1; i < sortedNodePoints.length; i++) {
			let rowNum = 0
			let fromSouth = 0
			if (sortedNodePoints[i]["xLoc"] === prevNode["xLoc"]) {
				rowNum = prevNode["rowNum"]
				fromSouth = prevNode["fromSouth"] + 1
			} else {
				rowNum = prevNode["rowNum"] + 1
				fromSouth = 1
			}
			prevNode = { ...sortedNodePoints[i], rowNum, fromSouth }
			assignedNodes.push(prevNode)
		}
	})
	return assignedNodes
}

const latLngToCoords = (latLngs) => {
	return latLngs[0].map((latLng, index) => {
		return [latLng["lat"], latLng["lng"]]
	})
}

const DEFAULT_DRAW_ITEMS = {
	polyline: false,
	rectangle: false,
	circle: false,
	marker: false,
	circlemarker: false,
	polygon: false,
}

const MasterAreaEditor = ({
	classes,
	locations,
	translations,
	masterAreas,
	masterAreaLabels,
	modifiedAreas,
	updateConfigMasterArea,
	setConfigLocations,
	setConfigAssignment,
	labelsVisible,
	masterLocations,
}) => {
	const [deleteActive, setDeleteActive] = useState(false)
	const [editKey, setEditKey] = useState(0)
	const tempAreas = { ...masterAreas, ...modifiedAreas }
	const { layoutId, ...newAreas } = tempAreas
	const areas = []
	const labels = []
	Object.keys(newAreas).forEach((mLocId) => {
		if (newAreas[mLocId].length) {
			const isUnassigned = mLocId.split("_").length > 1
			areas.push(
				<Polygon
					key={mLocId + editKey}
					mLocId={mLocId}
					color={"black"}
					positions={newAreas[mLocId]}
					onClick={deleteActive ? () => {} : () => setConfigAssignment("master-area", mLocId)}
				/>,
			)
			if (labelsVisible) {
				labels.push(
					<DivIcon key={mLocId} position={polylabel([newAreas[mLocId]], 1.0)}>
						<Typography
							className={classes.label}
							onClick={deleteActive ? null : () => setConfigAssignment("master-area", mLocId)}
						>
							{isUnassigned ? "Click to Assign" : masterAreaLabels[mLocId]}
						</Typography>
					</DivIcon>,
				)
			}
		}
	})

	/*
    Leaflet deals with event listeners by reference,
    so if you want to add a listener and then remove it, define it as a function.
    'useCallback' must be used to ensure reference isn't changed on consecutive renders
  */
	const handleCreated = useCallback(
		(modifiedAreas) => (e) => {
			// console.log('Popup assign to master', e)
			const layer = e.layer
			const area = latLngToCoords(layer.getLatLngs())
			updateConfigMasterArea({ ["TEMPID_" + Object.keys(modifiedAreas).length]: area })
			layer.remove()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[],
	)

	const handleEdit = useCallback((e) => {
		// console.log('Recalculate nodes assigned to master', e)
		const edits = {}
		e.layers.eachLayer((layer) => {
			edits[layer.options.mLocId] = latLngToCoords(layer.getLatLngs())
		})
		updateConfigMasterArea(edits)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleDelete = useCallback((e) => {
		// console.log('Recalculate nodes assigned to master', e)
		const edits = {}
		e.layers.eachLayer((layer) => {
			edits[layer.options.mLocId] = []
		})
		updateConfigMasterArea(edits)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleEditStart = useCallback((e) => {
		setEditKey((editKey) => editKey + 1) // force redraw master area polygon <- fixes area render bug
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const areaKeys = Object.keys(modifiedAreas)
		if (areaKeys.length && !areaKeys.reduce((accu, currVal) => accu || currVal.split("_").length === 2, false)) {
			const rowSpacing = getRowSpacing(locations)
			let nodes = assignNodesToMasters(locations, translations, newAreas, masterLocations)
			nodes = assignRowNums(rowSpacing, nodes)
			setConfigLocations(nodes)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modifiedAreas])

	return (
		<React.Fragment>
			{labels}
			<FeatureGroup>
				{areas}
				<EditControl
					position="bottomleft"
					onCreated={handleCreated(modifiedAreas)}
					onEdited={handleEdit}
					onDeleted={handleDelete}
					onEditStart={handleEditStart}
					// onEditStop={(e) => {}}
					// onMounted={() => {console.warn('onMounted')}}
					onDeleteStart={(e) => {
						setDeleteActive(true)
					}}
					onDeleteStop={(e) => {
						setDeleteActive(false)
					}}
					edit={{ remove: true }}
					draw={{
						...DEFAULT_DRAW_ITEMS,
						polygon: {
							allowIntersection: false,
							showArea: true,
						},
					}}
				/>
			</FeatureGroup>
		</React.Fragment>
	)
}

MasterAreaEditor.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MasterAreaEditor)

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "redux"
import MasterAreaEditor from "./MasterAreaEditor"
import { updateConfigMasterArea, setConfigLocations, setConfigAssignment } from "store/actions/reduxActions"
import { getLayoutMasterIds } from "utils"

const getMasterAreas = (state, props) => {
	const masterAreas = {}
	getLayoutMasterIds(state.layoutDetails, props.layoutId).forEach((mLocId) => {
		if (state.masterDetails.hasOwnProperty(mLocId) && state.masterDetails[mLocId].area) {
			masterAreas[mLocId] = state.masterDetails[mLocId].area
		}
	})
	return masterAreas
}

const getMasterLocations = (state, props) => {
	const masterAreas = {}
	getLayoutMasterIds(state.layoutDetails, props.layoutId).forEach((mLocId) => {
		if (
			state.masterDetails.hasOwnProperty(mLocId) &&
			state.masterDetails[mLocId].yLoc &&
			state.masterDetails[mLocId].xLoc
		) {
			masterAreas[mLocId] = [state.masterDetails[mLocId].yLoc, state.masterDetails[mLocId].xLoc]
		} else {
			masterAreas[mLocId] = null
		}
	})
	return masterAreas
}

const getMasterAreaLabels = (state, props) => {
	const masterAreaLabels = {}
	getLayoutMasterIds(state.layoutDetails, props.layoutId).forEach((mLocId) => {
		if (state.masterDetails.hasOwnProperty(mLocId)) {
			masterAreaLabels[mLocId] = state.masterDetails[mLocId].name
		}
	})
	return masterAreaLabels
}

const getLabelVisible = (state, props) => {
	const zoomLevel = state.layoutZoomLevel
	if (!zoomLevel) return false
	const labelZoomThreshold =
		state.layoutDetails[props.match.params.layoutId] &&
		state.layoutDetails[props.match.params.layoutId]["labelZoomThreshold"]
	if (!labelZoomThreshold) return true
	return zoomLevel >= labelZoomThreshold
}

const mapStateToProps = (state, props) => ({
	masterLocations: getMasterLocations(state, props),
	masterAreas: getMasterAreas(state, props),
	masterAreaLabels: getMasterAreaLabels(state, props),
	modifiedAreas: state.configMasterAreas,
	locations: state.configLocations,
	translations: state.configNodeTranslations,
	labelsVisible: getLabelVisible(state, props),
})

const mapDispatchToProps = (dispatch, props) => ({
	updateConfigMasterArea: (areaEdits) => dispatch(updateConfigMasterArea({ ...areaEdits, layoutId: props.layoutId })),
	setConfigLocations: (locations) => dispatch(setConfigLocations(locations)),
	setConfigAssignment: (key, value) => dispatch(setConfigAssignment({ key, value })),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MasterAreaEditor)

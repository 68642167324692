import React from "react"
import PropTypes from "prop-types"
import { Circle } from "react-leaflet"
import { randomColorArray } from "constants/color"

const getMarkerColors = (masterIndex) => {
	if (masterIndex === -1) return "#A6A6A6"
	return randomColorArray[masterIndex % 50]
}

const NodeMarker = ({ center, radius, colorIndex }) => {
	if (center.length !== 2) return null
	const markerColor = getMarkerColors(colorIndex)

	return <Circle color={markerColor} fillColor={markerColor} center={center} radius={radius} />
}

NodeMarker.propTypes = {
	center: PropTypes.array.isRequired,
}

export default NodeMarker

import { connect } from "react-redux"
import MasterCoordinateEditor from "./MasterCoordinateEditor"
import { setConfigMasterCoordinates } from "store/actions/reduxActions"
import { getLayoutMasterIds } from "utils"

import { updateMasterDetails } from "store/actions/setters"

const getMasterCoordinates = (state, props) => {
	const masterCoordinates = {}
	getLayoutMasterIds(state.layoutDetails, props.layoutId).forEach((mLocId) => {
		if (
			state.masterDetails.hasOwnProperty(mLocId) &&
			state.masterDetails[mLocId].xLoc &&
			state.masterDetails[mLocId].yLoc
		) {
			masterCoordinates[mLocId] = [state.masterDetails[mLocId].yLoc, state.masterDetails[mLocId].xLoc]
		}
	})
	return masterCoordinates
}

const getMasterLabels = (state, props) => {
	const masterAreaLabels = {}
	getLayoutMasterIds(state.layoutDetails, props.layoutId).forEach((mLocId) => {
		if (state.masterDetails.hasOwnProperty(mLocId)) {
			masterAreaLabels[mLocId] = state.masterDetails[mLocId].name
		}
	})
	return masterAreaLabels
}

const mapStateToProps = (state, props) => ({
	masterCoordinates: getMasterCoordinates(state, props),
	masterLabels: getMasterLabels(state, props),
	radius: (parseInt(state.configNodeTranslations.markerRadius) || 0.5) + 1,
})

const mapDispatchToProps = (dispatch, props) => ({
	setConfigMasterCoordinates: (coordinates) => dispatch(setConfigMasterCoordinates(coordinates)),
	updateMasterCoordinates: (mLocId, layoutId, coordinates) =>
		dispatch(updateMasterDetails(mLocId, layoutId, coordinates)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterCoordinateEditor)

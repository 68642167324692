const isValid = (input) => {
	if (input === null || input === undefined) {
		return false
	}
	if (input instanceof String || typeof input === "string") {
		return input.length > 0
	}
	return true
}

const compare = (a, b, orderBy, isDesc) => {
	if (
		orderBy === "masterName" &&
		(a[orderBy].toString().toLowerCase().includes("master") || b[orderBy].toString().toLowerCase().includes("master"))
	) {
		a[orderBy] = parseInt(a[orderBy].toString().replace(/\D/g, ""))
		b[orderBy] = parseInt(b[orderBy].toString().replace(/\D/g, ""))
	}
	const first = a[orderBy]
	const second = b[orderBy]
	const validFirst = isValid(first)
	const validSecond = isValid(second)
	if (validFirst && !validSecond) {
		return -1
	} else if (validSecond && !validFirst) {
		return 1
	}
	const compareResult = second > first ? -1 : second < first ? 1 : 0
	return compareResult * (isDesc ? 1 : -1)
}

const stableSort = (array, cmp) => {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const getSorting = (order, orderBy) => {
	return (a, b) => compare(a, b, orderBy, order === "desc")
}

export const sortRows = (rows, sortBy, sortDirection) => {
	if (!sortBy || !sortDirection) return rows
	let sortedRows = stableSort(rows, getSorting(sortDirection.toLowerCase(), sortBy))
	if (sortBy === "masterName") {
		sortedRows.forEach((item) => {
			if (typeof item["masterName"] === "number") {
				item["masterName"] = `Master ${item["masterName"]}`
			}
		})
	}
	return sortedRows
}

export const filterRows = (rows, filter) => {
	if (filter === "") return rows
	return rows.filter((rowData) => {
		let valid = false
		Object.keys(rowData).forEach((cellKey) => {
			if (cellKey !== "id" && rowData[cellKey]) {
				valid |= rowData[cellKey].toString().toLowerCase().includes(filter.toLowerCase())
			}
		})
		return valid
	})
}

import request from "superagent"
import { authRef, refreshCognitoSession } from "../config/aws"

export const discardStatuses = [400, 403, 404, 409]

export class HttpMethod {
	static GET = "GET"
	static POST = "POST"
	static PUT = "PUT"
	static PATCH = "PATCH"
	static DELETE = "DELETE"
}

class Api {
	idToken = localStorage.getItem("idToken")
	cognitoID = localStorage.getItem("cognitoID")

	constructor() {
		this.host = process.env.REACT_APP_REST_API_HOST
		if (!this.host) {
			throw new Error("REACT_APP_REST_API_HOST is not defined")
		}
		this._sendRequest = this._sendRequest.bind(this)
		this.requestCache = {}
	}

	setIdTokens(token) {
		this.idToken = token["jwtToken"]
		this.cognitoID = token["payload"]["cognito:username"]
		localStorage.setItem("cognitoID", this.cognitoID || "")
		localStorage.setItem("idToken", this.idToken || "")
	}

	signOut() {
		this.idToken = null
		this.cognitoID = null
		this.requestCache = {}
		localStorage.removeItem("cognitoID")
		localStorage.removeItem("idToken")
		authRef.signOut()
	}

	/**
	 * Sends a request to the API and returns a promise for the result.
	 * @param url
	 * @param method {"GET" | "POST" | "PUT" | "PATCH" | "DELETE"}
	 * @param payload {Object}
	 * @param queryArgs {Object}
	 * @param headers {Object}
	 * @param raiseException {boolean} Whether to throw an exception if an error is encountered sending the request
	 * @returns {Promise<request.Response>}
	 * @private
	 */
	async _sendRequest(
		reqUrl,
		method,
		payload = undefined,
		queryArgs = {},
		headers = {},
		raiseException = false,
		retryAuth = true,
	) {
		method = method.toUpperCase()
		if (!Object.values(HttpMethod).includes(method)) {
			throw new Error(`Method ${method} not supported by Api`)
		}
		let url = reqUrl
		if (!url.startsWith("/")) url = "/" + url
		url = new URL(url, this.host).toString().toLowerCase()

		// This is just a measure to prevent accidentally transmitting real passwords in plain text over the internet when
		// testing locally.
		const safeUrlPatterns = [
			"https://",
			"http://localhost:",
			"http://127.0.0.1:",
			"http://localhost/",
			"http://127.0.0.1/",
		]
		const isSafeUrl = safeUrlPatterns.find((pattern) => url.startsWith(pattern))

		if (!isSafeUrl) {
			throw new Error("Attempted to request an unsafe resource.")
		}

		const methodHandlers = {
			[HttpMethod.GET]: () => request.get(url),
			[HttpMethod.POST]: () => request.post(url).send(payload),
			[HttpMethod.PATCH]: () => request.patch(url).send(payload),
			[HttpMethod.PUT]: () => request.put(url).send(payload),
			[HttpMethod.DELETE]: () => request.delete(url),
		}

		return methodHandlers[method]()
			.auth(this.idToken, { type: "bearer" })
			.set(headers)
			.query(queryArgs)
			.catch(async (error) => {
				if (error.response?.statusCode === 401) {
					try {
						if (retryAuth) {
							const cognitoUser = await authRef.currentAuthenticatedUser()
							const currentSession = cognitoUser.getSignInUserSession()
							const newSession = await refreshCognitoSession(cognitoUser, currentSession)

							this.setIdTokens(newSession.getIdToken())
							return this._sendRequest(reqUrl, method, payload, queryArgs, headers, raiseException, false)
						}
					} catch (err) {
						console.log("refresh token error ", err)
					}
					this.signOut()
					// Redirect to login page
					if (window?.location) window.location.href = "/"
					if (raiseException) {
						throw error
					}
					return error.response
				} else if (error.response?.statusText) {
					console.error(error.response.statusText, error)
					if (raiseException) {
						throw error
					}
					return error.response
				} else {
					if (error.response) {
						console.error(error.response.statusText, error)
					} else {
						console.error("Unknown error occurred while sending request.")
					}
					if (raiseException) {
						throw error
					}
					return error.response
				}
			})
	}

	async verifyAuth(password) {
		return this._sendRequest("/auth/verify-authentication/", HttpMethod.POST, { password: password })
	}

	async getUserPermissions() {
		return this._sendRequest("/auth/permissions/", HttpMethod.GET)
	}

	async getWeatherSmart(siteUuid) {
		return this._sendRequest(`/sites/${siteUuid}/weathersmart/`, HttpMethod.GET)
	}

	async setWeatherSmart(siteUuid, value) {
		return this._sendRequest(`/sites/${siteUuid}/weathersmart/`, HttpMethod.PUT, { value: value })
	}

	async getSiteLayouts() {
		const cacheKey = "fetch-sites"
		if (!(cacheKey in this.requestCache)) {
			this.requestCache[cacheKey] = this._sendRequest("/sites/", HttpMethod.GET)
		}
		return this.requestCache[cacheKey]
	}

	async getSiteLayoutById(siteUuid) {
		return this._sendRequest(`/sites/${siteUuid}/`, HttpMethod.GET)
	}

	async getNodesForSite(siteUuid) {
		const cacheKey = `nodes-in-site-${siteUuid}`
		if (!(cacheKey in this.requestCache)) {
			this.requestCache[cacheKey] = this._sendRequest(`/sites/${siteUuid}/nodes/`, HttpMethod.GET)
		}
		return this.requestCache[cacheKey]
	}

	async setMasterBoard(masterUuid, payload) {
		return this._sendRequest(
			`/controllers/masters/${masterUuid}/components/board/`,
			HttpMethod.POST,
			payload,
			{},
			{},
			true,
		)
	}

	async setMasterBattery(masterUuid, payload) {
		return this._sendRequest(
			`/controllers/masters/${masterUuid}/components/battery/`,
			HttpMethod.POST,
			payload,
			{},
			{},
			true,
		)
	}

	async setMasterWindSensor(masterUuid, payload) {
		return this._sendRequest(
			`/controllers/masters/${masterUuid}/components/wind-sensor/`,
			HttpMethod.POST,
			payload,
			{},
			{},
			true,
		)
	}

	async setMasterSnowSensor(masterUuid, payload) {
		return this._sendRequest(
			`/controllers/masters/${masterUuid}/components/snow-sensor/`,
			HttpMethod.POST,
			payload,
			{},
			{},
			true,
		)
	}

	async setNodeEnclosure(nodeUuid, payload) {
		return this._sendRequest(`/controllers/nodes/${nodeUuid}/components/board/`, HttpMethod.POST, payload, {}, {}, true)
	}

	async setNodeActuator(nodeUuid, payload) {
		return this._sendRequest(
			`/controllers/nodes/${nodeUuid}/components/actuator/`,
			HttpMethod.POST,
			payload,
			{},
			{},
			true,
		)
	}

	async setNodeChargingPanel(nodeUuid, payload) {
		return this._sendRequest(
			`/controllers/nodes/${nodeUuid}/components/charging-panel/`,
			HttpMethod.POST,
			payload,
			{},
			{},
			true,
		)
	}

	async setNodeBattery(nodeUuid, payload) {
		return this._sendRequest(
			`/controllers/nodes/${nodeUuid}/components/battery/`,
			HttpMethod.POST,
			payload,
			{},
			{},
			true,
		)
	}

	deleteLayout(siteUuid) {
		return this._sendRequest(`/sites/${siteUuid}/`, HttpMethod.DELETE, undefined, {}, {}, true)
	}
}

const api = new Api()
export default api

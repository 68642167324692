import React, { useCallback, useEffect, useRef, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import green from "@material-ui/core/colors/green"
import { Link, Prompt } from "react-router-dom"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import LayoutSvgUploader from "../LayoutSvgUploader"
import MasterList from "../MasterList"
import Storage from "@aws-amplify/storage"
import DangerZone from "../DangerZone"
import PropTypes from "prop-types"
import PendingJobsManager, { subscriptionTypes } from "../../../utils/pendingJobsManager"

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 0,
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
		flex: 1,
		position: "relative",
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	wrapper: {
		margin: theme.spacing(0, 1),
		position: "relative",
	},
	actionWrapper: {
		justifyContent: "space-between",
	},
	actionWrapperRight: {
		display: "flex",
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	switchControl: {
		margin: theme.spacing(1),
		position: "absolute",
		zIndex: 100,
	},
	dangerZoneTab: {
		color: "#d32f2f",
	},
}))
const jobsManager = new PendingJobsManager()

const tabs = {
	MASTERS: 1,
	DANGER_ZONE: 2,
}

const EditLayout = ({
	location,
	history,
	layoutId,
	handleClose,
	createLayoutDetails,
	updateLayoutDetails,
	enqueueSnackbar,
	tabIndex,
	layoutDetails,
	handleNewLayoutRedirect,
	fetchMasterDetails,
}) => {
	const classes = useStyles()
	const [pendingResponse, _setPendingResponse] = useState(false)
	const [success, setSuccess] = useState(true)
	const [values, setValues] = useState({})
	const [closing, setClosing] = useState(false)

	const jobsStarted = useCallback(() => {
		_setPendingResponse(true)
	}, [_setPendingResponse])

	const jobsFinished = useCallback(() => {
		_setPendingResponse(false)
	}, [_setPendingResponse])

	useEffect(() => {
		jobsManager.subscribe(subscriptionTypes.jobsStarted, jobsStarted)
		jobsManager.subscribe(subscriptionTypes.allJobsFinished, jobsFinished)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (layoutId) {
			fetchMasterDetails(layoutId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [layoutId])

	const handleChange = (name, type) => (val) => {
		let value = null
		switch (type) {
			case "bool":
				value = val.target.checked
				break
			case "string":
				value = val
				break
			case "eInt":
				value = parseInt(val.target.value)
				if (isNaN(value)) value = ""
				break
			case "eFloat":
				value = parseFloat(val.target.value)
				if (isNaN(value)) value = ""
				break
			case "object":
				value = val
				break
			default:
				value = val.target.value
				break
		}
		setValues((values) => ({ ...values, [name]: value }))
		setSuccess(false)
	}

	const getValue = (name) => {
		if (values[name] !== undefined) {
			return values[name]
		}
		if (layoutDetails[name] !== undefined && layoutDetails[name] !== null) {
			return layoutDetails[name]
		}
	}

	const getCoordinate = (name) => {
		if (values[name] !== undefined) {
			return values[name]
		}
		if (layoutDetails["coordinates"]) {
			return layoutDetails["coordinates"][name]
		}
		return ""
	}

	const submit = async (e) => {
		e.preventDefault()
		let result = null
		const { latitude, longitude, layoutFile, ...otherLayoutDetails } = values
		let newLayoutDetails = { ...otherLayoutDetails }
		if (layoutFile) {
			const name = newLayoutDetails["name"] || layoutDetails["name"]
			try {
				const newUrl = `layouts/${name}-${Math.floor(Math.random() * 16777215).toString(16)}.svg`
				result = await Storage.put(newUrl, layoutFile, {
					contentType: "image/svg+xml",
				})
				newLayoutDetails["layoutUrl"] = newUrl
			} catch (err) {
				console.log("Storage ERROR:", err)
			}
		}
		jobsManager.reportJobStarted("submit-layout-changes")
		setSuccess(false)
		if (latitude && longitude) {
			newLayoutDetails = { ...newLayoutDetails, coordinates: { latitude, longitude } }
		} else if (layoutDetails["coordinates"] && latitude) {
			newLayoutDetails = { ...newLayoutDetails, coordinates: layoutDetails["coordinates"] }
			newLayoutDetails.coordinates["latitude"] = latitude
		} else if (layoutDetails["coordinates"] && longitude) {
			newLayoutDetails = { ...newLayoutDetails, coordinates: layoutDetails["coordinates"] }
			newLayoutDetails.coordinates["longitude"] = longitude
		}
		let timer = setTimeout(() => {
			jobsManager.reportJobFinished("submit-layout-changes")
			setSuccess(false)
			enqueueSnackbar("Save Timeout", {
				variant: "error",
			})
		}, 20000)

		try {
			if (layoutId === "new") {
				newLayoutDetails["mLocIds"] = []
				const { name, ...otherDetails } = newLayoutDetails
				result = await createLayoutDetails(name, otherDetails)
			} else {
				result = await updateLayoutDetails(layoutId, newLayoutDetails)
			}
			clearTimeout(timer)
			enqueueSnackbar("Layout saved successfully", {
				variant: "success",
			})
			jobsManager.reportJobFinished("submit-layout-changes")
			setSuccess(true)
			if (layoutId === "new" && result && result.id) {
				handleNewLayoutRedirect(result.id)
			}
		} catch (err) {
			console.error(err)
			clearTimeout(timer)
			enqueueSnackbar("Save Error", {
				variant: "error",
			})
			jobsManager.reportJobFinished("submit-layout-changes")
			setSuccess(false)
		}
	}

	const handleTabChange = (e, newValue) => {
		let params = new URLSearchParams(location.search)
		params.set("tab", newValue)
		let queryParams = "?" + params.toString()
		history.push({
			...location,
			search: queryParams,
		})
	}

	const getMainTabContent = () => {
		return (
			<DialogContent>
				<LayoutSvgUploader
					layoutName={getValue("name")}
					layoutUrl={getValue("layoutUrl")}
					onUrlRemove={() => handleChange("layoutUrl", "string")(null)}
					layoutFile={getValue("layoutFile")}
					onFileSelect={handleChange("layoutFile", "object")}
					onFileRemove={() => handleChange("layoutFile", "object")(undefined)}
				/>
				<TextField
					id="layout-name"
					label="Layout Name"
					type="text"
					margin="normal"
					variant="outlined"
					value={getValue("name") || ""}
					onChange={handleChange("name")}
					required
					fullWidth
				/>
				<TextField
					id="powerCapacityDc"
					label="Power Capacity (DC)"
					type="number"
					margin="normal"
					variant="outlined"
					value={getValue("powerCapacityDc") || ""}
					onChange={handleChange("powerCapacityDc", "eFloat")}
					inputProps={{ min: "0", step: "any" }}
					fullWidth
				/>
				<TextField
					id="utcOffset"
					label="UTC Offset"
					type="number"
					margin="normal"
					variant="outlined"
					value={getValue("utcOffset") || ""}
					onChange={handleChange("utcOffset", "eInt")}
					inputProps={{ min: "-12", max: "12" }}
					required
					fullWidth
				/>
				<Divider className={classes.divider} />
				<FormLabel component="legend">Layout Coordinates</FormLabel>
				<FormGroup>
					<TextField
						id="latitude"
						label="Latitude"
						type="number"
						margin="normal"
						variant="outlined"
						value={getCoordinate("latitude") || ""}
						onChange={handleChange("latitude", "eFloat")}
						required={Boolean(values.longitude)}
						inputProps={{ min: "-90", max: "90", step: "any" }}
						fullWidth
					/>
					<TextField
						id="longitude"
						label="Longitude"
						type="number"
						margin="normal"
						variant="outlined"
						value={getCoordinate("longitude")}
						onChange={handleChange("longitude", "eFloat")}
						required={Boolean(values.latitude)}
						inputProps={{ min: "-180", max: "180", step: "any" }}
						fullWidth
					/>
				</FormGroup>
				<Divider className={classes.divider} />
				<FormLabel component="legend">Layout Address</FormLabel>
				<FormGroup>
					<TextField
						id="address1"
						label="Address 1"
						type="text"
						margin="normal"
						variant="outlined"
						value={getValue("address1")}
						onChange={handleChange("address1")}
						fullWidth
					/>
					<TextField
						id="state"
						label="State Abbreviation"
						type="text"
						margin="normal"
						variant="outlined"
						value={getValue("state")}
						onChange={handleChange("state")}
						fullWidth
					/>
				</FormGroup>
				<Divider className={classes.divider} />
				<FormLabel component="legend">Companies</FormLabel>
				<FormGroup>
					<TextField
						id="customerName"
						label="Customer"
						type="text"
						margin="normal"
						variant="outlined"
						value={getValue("customerName")}
						onChange={handleChange("customerName")}
						fullWidth
					/>
				</FormGroup>
			</DialogContent>
		)
	}

	const getMastersListTabContent = () => <MasterList layoutId={layoutId} />

	const getDangerZoneTabContent = () => {
		if (!layoutDetails.name) return <h1>No layout selected</h1> // TODO: Set disabled={!layoutDetails.name}
		return <DangerZone layoutId={layoutId} layoutDetails={layoutDetails} jobsManager={jobsManager} close={close} />
	}

	const unmounted = useRef(false)

	useEffect(() => {
		return () => {
			unmounted.current = true
		}
	}, [])

	const close = useCallback(() => {
		if (!closing && !unmounted.current) {
			setClosing(true)
		}
	}, [closing])

	useEffect(() => {
		if (closing) {
			handleClose()
		}
	}, [closing, handleClose])

	return (
		<div className={classes.root}>
			<AppBar position="static" color="default">
				<Tabs value={tabIndex || 0} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
					<Tab label="Layout Details" />
					<Tab label="Masters List" />
					<Tab label="Danger Zone" className={classes.dangerZoneTab} />
				</Tabs>
			</AppBar>
			<form className={classes.form} onSubmit={submit}>
				<Prompt when={!success} message={"Continue without saving?"} />
				{!tabIndex && getMainTabContent()}
				{tabIndex === tabs.MASTERS && getMastersListTabContent()}
				{tabIndex === tabs.DANGER_ZONE && getDangerZoneTabContent()}
				<DialogActions className={classes.actionWrapper}>
					<Button color="primary" component={Link} to={`/configure-layout/${layoutId}`}>
						Configure
					</Button>
					<div className={classes.actionWrapperRight}>
						<Button color="primary" onClick={(_e) => close()}>
							Close
						</Button>
						<div className={classes.wrapper}>
							<Button type="submit" variant="contained" color="primary" disabled={pendingResponse || success}>
								{success ? "Saved" : "Save Changes"}
							</Button>
							{pendingResponse && <CircularProgress size={24} className={classes.buttonProgress} />}
						</div>
					</div>
				</DialogActions>
			</form>
		</div>
	)
}

EditLayout.propTypes = {
	location: PropTypes.any.isRequired, // TODO: No any
	history: PropTypes.object.isRequired,
	layoutId: PropTypes.string.isRequired,
	handleClose: PropTypes.func.isRequired,
	createLayoutDetails: PropTypes.func.isRequired,
	updateLayoutDetails: PropTypes.func.isRequired,
	enqueueSnackbar: PropTypes.func.isRequired,
	tabIndex: PropTypes.number.isRequired,
	layoutDetails: PropTypes.object.isRequired, // TODO: Shape
	handleNewLayoutRedirect: PropTypes.func.isRequired,
	fetchMasterDetails: PropTypes.func.isRequired,
}

export default EditLayout

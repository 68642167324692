/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCommandFeedback = /* GraphQL */ `
  mutation CreateCommandFeedback($input: CreateCommandFeedbackInput!) {
    createCommandFeedback(input: $input) {
      mId
      name
      status
      message
      __typename
    }
  }
`;
export const createCommandRequest = /* GraphQL */ `
  mutation CreateCommandRequest($input: CreateCommandRequestInput!) {
    createCommandRequest(input: $input) {
      name
      status
      message
      __typename
    }
  }
`;
export const createLatestMasterData = /* GraphQL */ `
  mutation CreateLatestMasterData($input: CreateLatestMasterDataInput!) {
    createLatestMasterData(input: $input) {
      layoutId
      mId
      mLocId
      activeBaseline
      alarms
      batteryVoltage
      bootTime
      chargeCurrent
      depth
      flags
      readStatus
      resetType
      stowLimitEast
      stowLimitWest
      stowModesActive
      stowModesEnabled
      tempBoard
      tempCharger
      timestamp
      trackingMode
      trueBaseline
      windAverage
      windPeak
      fwMaster
      fwNode
      arrrDuration
      __typename
    }
  }
`;
export const createLatestNodeDailySummary = /* GraphQL */ `
  mutation CreateLatestNodeDailySummary(
    $input: CreateLatestNodeDailySummaryInput!
  ) {
    createLatestNodeDailySummary(input: $input) {
      layoutId
      mId
      mLocId
      oAndMName
      ownerName
      __typename
    }
  }
`;
export const createLatestNodeData = /* GraphQL */ `
  mutation CreateLatestNodeData($input: CreateLatestNodeDataInput!) {
    createLatestNodeData(input: $input) {
      layoutId
      mLocId
      nId
      nLocId
      __typename
    }
  }
`;
export const createLatestNodeDataSummary = /* GraphQL */ `
  mutation CreateLatestNodeDataSummary(
    $input: CreateLatestNodeDataSummaryInput!
  ) {
    createLatestNodeDataSummary(input: $input) {
      layoutId
      mId
      mLocId
      oAndMName
      ownerName
      __typename
    }
  }
`;
export const createLayoutDetails = /* GraphQL */ `
  mutation CreateLayoutDetails($input: CreateLayoutDetailsInput!) {
    createLayoutDetails(input: $input) {
      address1
      address2
      city
      coordinates {
        latitude
        longitude
        __typename
      }
      deltaAction
      developerName
      id
      installerName
      labelZoomThreshold
      layoutUrl
      mLocIds
      markerRadius
      name
      nameAlternate
      customerName
      oAndMName
      ownerName
      powerCapacityDc
      published
      scadaName
      state
      utcOffset
      zip
      demo
      layoutNumber
      tz
      weathersmartEnabled
      __typename
    }
  }
`;
export const createMasterConfig = /* GraphQL */ `
  mutation CreateMasterConfig($input: CreateMasterConfigInput!) {
    createMasterConfig(input: $input) {
      layoutId
      mId
      mLocId
      __typename
    }
  }
`;
export const createMasterConfigLog = /* GraphQL */ `
  mutation CreateMasterConfigLog($input: CreateMasterConfigLogInput!) {
    createMasterConfigLog(input: $input) {
      mId
      mLocId
      timestamp
      __typename
    }
  }
`;
export const createMasterIdHistory = /* GraphQL */ `
  mutation CreateMasterIdHistory($input: CreateMasterIdHistoryInput!) {
    createMasterIdHistory(input: $input) {
      id
      layoutId
      mId
      mLocId
      timestamp
      __typename
    }
  }
`;
export const createMasterLocation = /* GraphQL */ `
  mutation CreateMasterLocation($input: CreateMasterLocationInput!) {
    createMasterLocation(input: $input) {
      area
      channel
      deltaAction
      deviceType
      id
      layoutId
      mId
      name
      label
      panid
      spaParamId
      nodeParamId
      xLoc
      yLoc
      localIp
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      mLocId
      timestamp
      userId
      username
      nId
      issueType
      layoutName
      masterName
      actuatorOn
      movesEastWest
      newNodeId
      commandQueue
      comment
      panelOn
      currentVoltageGood
      batteryGood
      goodPanelId
      badPanelId
      voltage
      current
      overCurrent
      lightOn
      eStopRespond
      eStopRespondReset
      tracking
      rmaNumbers
      rowNum
      fromSouth
      badActuatorModelNumber
      badActuatorProductionDate
      badActuatorVendorInitial
      badActuatorSerialNum
      goodActuatorModelNumber
      goodActuatorProductionDate
      goodActuatorVendorInitial
      goodActuatorSerialNum
      failPosition
      failAngle
      actuatorAbnormalities
      nodeIdMatch
      nodeFuse
      eStopPulled
      goodBatteryId
      badBatteryId
      goodBatteryVendor
      badBatteryVendor
      mechanicalIssues
      fixedWiring
      damageType
      goodChannel
      goodPanId
      badChannel
      badPanId
      targetedBattery
      retrofitComponent
      boardVendor
      bomAlternate
      bareBoardVersion
      generation
      buildDate
      batchNumber
      __typename
    }
  }
`;
export const createNodeLocations = /* GraphQL */ `
  mutation CreateNodeLocations($input: CreateNodeLocationsInput!) {
    createNodeLocations(input: $input) {
      mLocId
      layoutId
      nIds {
        index
        id
        __typename
      }
      syncBitmasks
      nLocs {
        index
        xLoc
        yLoc
        rowNum
        fromSouth
        distance
        role
        relative
        __typename
      }
      deltaAction
      __typename
    }
  }
`;
export const createReplacementCampaignInfo = /* GraphQL */ `
  mutation CreateReplacementCampaignInfo(
    $input: CreateReplacementCampaignInput!
  ) {
    createReplacementCampaignInfo(input: $input) {
      layoutId
      mLocId
      timestamp
      replacements {
        index
        id
        __typename
      }
      __typename
    }
  }
`;
export const createNodeConfig = /* GraphQL */ `
  mutation CreateNodeConfig($input: CreateNodeConfigInput!) {
    createNodeConfig(input: $input) {
      layoutId
      mLocId
      nLocId
      __typename
    }
  }
`;
export const createSpaParameters = /* GraphQL */ `
  mutation CreateSpaParameters($input: CreateSpaParametersInput!) {
    createSpaParameters(input: $input) {
      id
      label
      mLocIds
      utcOffset
      deltaUt1
      deltaT
      longitude
      latitude
      elevation
      pressure
      aveTemp
      slope
      azmRotation
      atmosRefraction
      rowSpacing
      panelLength
      planeInclinationAngle
      slopeDirection
      mechLimitWest
      mechLimitEast
      overnightAngle
      overnightMoveStartHour
      overnightMoveEndHour
      antishadingEnabled
      strongestAngle
      snowStowAngle
      floodStowAngle
      fastPollingInterval
      nodeTemperatureCalibrationEnabled
      nodeTemperatureCalibrationInterval
      windStowTable
      __typename
    }
  }
`;
export const createSpaParametersRequest = /* GraphQL */ `
  mutation CreateSpaParametersRequest(
    $input: CreateSpaParametersRequestInput!
  ) {
    createSpaParametersRequest(input: $input) {
      id
      label
      mLocIds
      utcOffset
      deltaUt1
      deltaT
      longitude
      latitude
      elevation
      pressure
      aveTemp
      slope
      azmRotation
      atmosRefraction
      rowSpacing
      panelLength
      planeInclinationAngle
      slopeDirection
      mechLimitWest
      mechLimitEast
      overnightAngle
      overnightMoveStartHour
      overnightMoveEndHour
      antishadingEnabled
      strongestAngle
      snowStowAngle
      floodStowAngle
      fastPollingInterval
      nodeTemperatureCalibrationEnabled
      nodeTemperatureCalibrationInterval
      windStowTable
      __typename
    }
  }
`;
export const createNodeParameters = /* GraphQL */ `
  mutation CreateNodeParameters($input: CreateNodeParametersInput!) {
    createNodeParameters(input: $input) {
      id
      label
      newNode
      lowBatteryLimit
      criticalBatteryLimit
      overvoltageThreshold
      highCurrentLimit
      lowBatteryStow
      numberofAngles
      motorOnTime
      motorOffTime
      motorOnTimeStow
      motorOffTimeStow
      angleTolerance
      startTolerance
      startToleranceAntishade
      motionTolerance
      noMotionTime
      directionTolerance
      hardwareCurrentLimit
      otaTimeout
      hotTempHiLimit
      hotTempLowLimit
      coldTempHiLimit
      coldTempLowLimit
      motionFailures
      motorMinSpeed
      rampOnTime
      rampDownPropCons
      overcurrentTries
      overcurrentTime
      arrrStatusIntervalMinute
      directionTime
      waitTimeMotorOff
      waitTimeChargerOff
      limitFailures
      limitTolerance
      directionFailures
      estopDisableTime
      lowBattThresholdDuringMotion
      nodeBattSocStowEnable
      nodeSocBattVoltOffset
      nodeBattSocStowTempHysteresis
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      username
      userCreateDate
      userLastModifiedDate
      enabled
      userStatus
      nameLast
      nameFirst
      email
      company
      companyRole
      allLayouts
      expiration
      __typename
    }
  }
`;
export const deleteLatestMasterData = /* GraphQL */ `
  mutation DeleteLatestMasterData($input: DeleteLatestMasterDataInput!) {
    deleteLatestMasterData(input: $input) {
      layoutId
      mId
      mLocId
      activeBaseline
      alarms
      batteryVoltage
      bootTime
      chargeCurrent
      depth
      flags
      readStatus
      resetType
      stowLimitEast
      stowLimitWest
      stowModesActive
      stowModesEnabled
      tempBoard
      tempCharger
      timestamp
      trackingMode
      trueBaseline
      windAverage
      windPeak
      fwMaster
      fwNode
      arrrDuration
      __typename
    }
  }
`;
export const deleteLatestNodeDailySummary = /* GraphQL */ `
  mutation DeleteLatestNodeDailySummary(
    $input: DeleteLatestNodeDailySummaryInput!
  ) {
    deleteLatestNodeDailySummary(input: $input) {
      layoutId
      mId
      mLocId
      oAndMName
      ownerName
      __typename
    }
  }
`;
export const deleteLatestNodeData = /* GraphQL */ `
  mutation DeleteLatestNodeData($input: DeleteLatestNodeDataInput!) {
    deleteLatestNodeData(input: $input) {
      layoutId
      mLocId
      nId
      nLocId
      __typename
    }
  }
`;
export const deleteLatestNodeDataSummary = /* GraphQL */ `
  mutation DeleteLatestNodeDataSummary(
    $input: DeleteLatestNodeDataSummaryInput!
  ) {
    deleteLatestNodeDataSummary(input: $input) {
      layoutId
      mId
      mLocId
      oAndMName
      ownerName
      __typename
    }
  }
`;
export const deleteLayoutDetails = /* GraphQL */ `
  mutation DeleteLayoutDetails($input: DeleteInput!) {
    deleteLayoutDetails(input: $input) {
      address1
      address2
      city
      coordinates {
        latitude
        longitude
        __typename
      }
      deltaAction
      developerName
      id
      installerName
      labelZoomThreshold
      layoutUrl
      mLocIds
      markerRadius
      name
      nameAlternate
      customerName
      oAndMName
      ownerName
      powerCapacityDc
      published
      scadaName
      state
      utcOffset
      zip
      demo
      layoutNumber
      tz
      weathersmartEnabled
      __typename
    }
  }
`;
export const deleteNodeLocations = /* GraphQL */ `
  mutation DeleteNodeLocations($input: DeleteNodeLocationsInput!) {
    deleteNodeLocations(input: $input) {
      mLocId
      layoutId
      nIds {
        index
        id
        __typename
      }
      syncBitmasks
      nLocs {
        index
        xLoc
        yLoc
        rowNum
        fromSouth
        distance
        role
        relative
        __typename
      }
      deltaAction
      __typename
    }
  }
`;
export const deleteReplacementCampaignInfo = /* GraphQL */ `
  mutation DeleteReplacementCampaignInfo(
    $input: DeleteReplacementCampaignInput!
  ) {
    deleteReplacementCampaignInfo(input: $input) {
      layoutId
      mLocId
      timestamp
      replacements {
        index
        id
        __typename
      }
      __typename
    }
  }
`;
export const deleteMasterConfig = /* GraphQL */ `
  mutation DeleteMasterConfig($input: DeleteMasterConfigInput!) {
    deleteMasterConfig(input: $input) {
      layoutId
      mId
      mLocId
      __typename
    }
  }
`;
export const deleteMasterConfigLog = /* GraphQL */ `
  mutation DeleteMasterConfigLog($input: DeleteMasterConfigLogInput!) {
    deleteMasterConfigLog(input: $input) {
      mId
      mLocId
      timestamp
      __typename
    }
  }
`;
export const deleteMasterIdHistory = /* GraphQL */ `
  mutation DeleteMasterIdHistory($input: DeleteMasterIdHistoryInput!) {
    deleteMasterIdHistory(input: $input) {
      id
      layoutId
      mId
      mLocId
      timestamp
      __typename
    }
  }
`;
export const deleteMasterLocation = /* GraphQL */ `
  mutation DeleteMasterLocation($input: DeleteMasterLocationInput!) {
    deleteMasterLocation(input: $input) {
      area
      channel
      deltaAction
      deviceType
      id
      layoutId
      mId
      name
      label
      panid
      spaParamId
      nodeParamId
      xLoc
      yLoc
      localIp
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask($input: DeleteTaskInput!) {
    deleteTask(input: $input) {
      mLocId
      timestamp
      userId
      username
      nId
      issueType
      layoutName
      masterName
      actuatorOn
      movesEastWest
      newNodeId
      commandQueue
      comment
      panelOn
      currentVoltageGood
      batteryGood
      goodPanelId
      badPanelId
      voltage
      current
      overCurrent
      lightOn
      eStopRespond
      eStopRespondReset
      tracking
      rmaNumbers
      rowNum
      fromSouth
      badActuatorModelNumber
      badActuatorProductionDate
      badActuatorVendorInitial
      badActuatorSerialNum
      goodActuatorModelNumber
      goodActuatorProductionDate
      goodActuatorVendorInitial
      goodActuatorSerialNum
      failPosition
      failAngle
      actuatorAbnormalities
      nodeIdMatch
      nodeFuse
      eStopPulled
      goodBatteryId
      badBatteryId
      goodBatteryVendor
      badBatteryVendor
      mechanicalIssues
      fixedWiring
      damageType
      goodChannel
      goodPanId
      badChannel
      badPanId
      targetedBattery
      retrofitComponent
      boardVendor
      bomAlternate
      bareBoardVersion
      generation
      buildDate
      batchNumber
      __typename
    }
  }
`;
export const deleteNodeConfig = /* GraphQL */ `
  mutation DeleteNodeConfig($input: DeleteNodeConfigInput!) {
    deleteNodeConfig(input: $input) {
      layoutId
      mLocId
      nLocId
      __typename
    }
  }
`;
export const deleteSpaParameters = /* GraphQL */ `
  mutation DeleteSpaParameters($input: DeleteInput!) {
    deleteSpaParameters(input: $input) {
      id
      label
      mLocIds
      utcOffset
      deltaUt1
      deltaT
      longitude
      latitude
      elevation
      pressure
      aveTemp
      slope
      azmRotation
      atmosRefraction
      rowSpacing
      panelLength
      planeInclinationAngle
      slopeDirection
      mechLimitWest
      mechLimitEast
      overnightAngle
      overnightMoveStartHour
      overnightMoveEndHour
      antishadingEnabled
      strongestAngle
      snowStowAngle
      floodStowAngle
      fastPollingInterval
      nodeTemperatureCalibrationEnabled
      nodeTemperatureCalibrationInterval
      windStowTable
      __typename
    }
  }
`;
export const deleteNodeParameters = /* GraphQL */ `
  mutation DeleteNodeParameters($input: DeleteInput!) {
    deleteNodeParameters(input: $input) {
      id
      label
      newNode
      lowBatteryLimit
      criticalBatteryLimit
      overvoltageThreshold
      highCurrentLimit
      lowBatteryStow
      numberofAngles
      motorOnTime
      motorOffTime
      motorOnTimeStow
      motorOffTimeStow
      angleTolerance
      startTolerance
      startToleranceAntishade
      motionTolerance
      noMotionTime
      directionTolerance
      hardwareCurrentLimit
      otaTimeout
      hotTempHiLimit
      hotTempLowLimit
      coldTempHiLimit
      coldTempLowLimit
      motionFailures
      motorMinSpeed
      rampOnTime
      rampDownPropCons
      overcurrentTries
      overcurrentTime
      arrrStatusIntervalMinute
      directionTime
      waitTimeMotorOff
      waitTimeChargerOff
      limitFailures
      limitTolerance
      directionFailures
      estopDisableTime
      lowBattThresholdDuringMotion
      nodeBattSocStowEnable
      nodeSocBattVoltOffset
      nodeBattSocStowTempHysteresis
      __typename
    }
  }
`;
export const updateLatestMasterData = /* GraphQL */ `
  mutation UpdateLatestMasterData($input: UpdateLatestMasterDataInput!) {
    updateLatestMasterData(input: $input) {
      layoutId
      mId
      mLocId
      activeBaseline
      alarms
      batteryVoltage
      bootTime
      chargeCurrent
      depth
      flags
      readStatus
      resetType
      stowLimitEast
      stowLimitWest
      stowModesActive
      stowModesEnabled
      tempBoard
      tempCharger
      timestamp
      trackingMode
      trueBaseline
      windAverage
      windPeak
      fwMaster
      fwNode
      arrrDuration
      __typename
    }
  }
`;
export const updateLatestNodeDailySummary = /* GraphQL */ `
  mutation UpdateLatestNodeDailySummary(
    $input: UpdateLatestNodeDailySummaryInput!
  ) {
    updateLatestNodeDailySummary(input: $input) {
      layoutId
      mId
      mLocId
      oAndMName
      ownerName
      __typename
    }
  }
`;
export const updateLatestNodeData = /* GraphQL */ `
  mutation UpdateLatestNodeData($input: UpdateLatestNodeDataInput!) {
    updateLatestNodeData(input: $input) {
      layoutId
      mLocId
      nId
      nLocId
      __typename
    }
  }
`;
export const updateLatestNodeDataSummary = /* GraphQL */ `
  mutation UpdateLatestNodeDataSummary(
    $input: UpdateLatestNodeDataSummaryInput!
  ) {
    updateLatestNodeDataSummary(input: $input) {
      layoutId
      mId
      mLocId
      oAndMName
      ownerName
      __typename
    }
  }
`;
export const updateLayoutDetails = /* GraphQL */ `
  mutation UpdateLayoutDetails($input: UpdateLayoutDetailsInput!) {
    updateLayoutDetails(input: $input) {
      address1
      address2
      city
      coordinates {
        latitude
        longitude
        __typename
      }
      deltaAction
      developerName
      id
      installerName
      labelZoomThreshold
      layoutUrl
      mLocIds
      markerRadius
      name
      nameAlternate
      customerName
      oAndMName
      ownerName
      powerCapacityDc
      published
      scadaName
      state
      utcOffset
      zip
      demo
      layoutNumber
      tz
      weathersmartEnabled
      __typename
    }
  }
`;
export const updateNodeLocations = /* GraphQL */ `
  mutation UpdateNodeLocations($input: UpdateNodeLocationsInput!) {
    updateNodeLocations(input: $input) {
      mLocId
      layoutId
      nIds {
        index
        id
        __typename
      }
      syncBitmasks
      nLocs {
        index
        xLoc
        yLoc
        rowNum
        fromSouth
        distance
        role
        relative
        __typename
      }
      deltaAction
      __typename
    }
  }
`;
export const updateReplacementCampaignInfo = /* GraphQL */ `
  mutation UpdateReplacementCampaignInfo(
    $input: UpdateReplacementCampaignInfoInput!
  ) {
    updateReplacementCampaignInfo(input: $input) {
      layoutId
      mLocId
      nLocId
      nId
      __typename
    }
  }
`;
export const updateMasterConfig = /* GraphQL */ `
  mutation UpdateMasterConfig($input: UpdateMasterConfigInput!) {
    updateMasterConfig(input: $input) {
      layoutId
      mId
      mLocId
      __typename
    }
  }
`;
export const updateMasterConfigLog = /* GraphQL */ `
  mutation UpdateMasterConfigLog($input: UpdateMasterConfigLogInput!) {
    updateMasterConfigLog(input: $input) {
      mId
      mLocId
      timestamp
      __typename
    }
  }
`;
export const updateMasterIdHistory = /* GraphQL */ `
  mutation UpdateMasterIdHistory($input: UpdateMasterIdHistoryInput!) {
    updateMasterIdHistory(input: $input) {
      id
      layoutId
      mId
      mLocId
      timestamp
      __typename
    }
  }
`;
export const updateMasterLocation = /* GraphQL */ `
  mutation UpdateMasterLocation($input: UpdateMasterLocationInput!) {
    updateMasterLocation(input: $input) {
      area
      channel
      deltaAction
      deviceType
      id
      layoutId
      mId
      name
      label
      panid
      spaParamId
      nodeParamId
      xLoc
      yLoc
      localIp
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      mLocId
      timestamp
      userId
      username
      nId
      issueType
      layoutName
      masterName
      actuatorOn
      movesEastWest
      newNodeId
      commandQueue
      comment
      panelOn
      currentVoltageGood
      batteryGood
      goodPanelId
      badPanelId
      voltage
      current
      overCurrent
      lightOn
      eStopRespond
      eStopRespondReset
      tracking
      rmaNumbers
      rowNum
      fromSouth
      badActuatorModelNumber
      badActuatorProductionDate
      badActuatorVendorInitial
      badActuatorSerialNum
      goodActuatorModelNumber
      goodActuatorProductionDate
      goodActuatorVendorInitial
      goodActuatorSerialNum
      failPosition
      failAngle
      actuatorAbnormalities
      nodeIdMatch
      nodeFuse
      eStopPulled
      goodBatteryId
      badBatteryId
      goodBatteryVendor
      badBatteryVendor
      mechanicalIssues
      fixedWiring
      damageType
      goodChannel
      goodPanId
      badChannel
      badPanId
      targetedBattery
      retrofitComponent
      boardVendor
      bomAlternate
      bareBoardVersion
      generation
      buildDate
      batchNumber
      __typename
    }
  }
`;
export const updateNodeConfig = /* GraphQL */ `
  mutation UpdateNodeConfig($input: UpdateNodeConfigInput!) {
    updateNodeConfig(input: $input) {
      layoutId
      mLocId
      nLocId
      __typename
    }
  }
`;
export const updateNodeId = /* GraphQL */ `
  mutation UpdateNodeId($input: UpdateNodeIdInput!) {
    updateNodeId(input: $input) {
      layoutId
      mLocId
      nLocId
      nId
      timestamp
      serverTimestamp
      userId
      username
      mId
      masterName
      layoutName
      deltaAction
      __typename
    }
  }
`;
export const updateNodeSyncBitmasks = /* GraphQL */ `
  mutation UpdateNodeSyncBitmasks($input: updateNodeSyncBitmasksInput!) {
    updateNodeSyncBitmasks(input: $input) {
      layoutId
      mLocId
      bitmasks
      fullSyncTimestamp
      __typename
    }
  }
`;
export const updateMasterId = /* GraphQL */ `
  mutation UpdateMasterId($input: UpdateMasterIdInput!) {
    updateMasterId(input: $input) {
      layoutId
      mLocId
      mId
      timestamp
      serverTimestamp
      userId
      username
      masterName
      layoutName
      deltaAction
      __typename
    }
  }
`;
export const updateSpaParameters = /* GraphQL */ `
  mutation UpdateSpaParameters($input: UpdateSpaParametersInput!) {
    updateSpaParameters(input: $input) {
      id
      label
      mLocIds
      utcOffset
      deltaUt1
      deltaT
      longitude
      latitude
      elevation
      pressure
      aveTemp
      slope
      azmRotation
      atmosRefraction
      rowSpacing
      panelLength
      planeInclinationAngle
      slopeDirection
      mechLimitWest
      mechLimitEast
      overnightAngle
      overnightMoveStartHour
      overnightMoveEndHour
      antishadingEnabled
      strongestAngle
      snowStowAngle
      floodStowAngle
      fastPollingInterval
      nodeTemperatureCalibrationEnabled
      nodeTemperatureCalibrationInterval
      windStowTable
      __typename
    }
  }
`;
export const updateSpaParametersRequest = /* GraphQL */ `
  mutation UpdateSpaParametersRequest(
    $input: UpdateSpaParametersRequestInput!
  ) {
    updateSpaParametersRequest(input: $input) {
      id
      label
      mLocIds
      utcOffset
      deltaUt1
      deltaT
      longitude
      latitude
      elevation
      pressure
      aveTemp
      slope
      azmRotation
      atmosRefraction
      rowSpacing
      panelLength
      planeInclinationAngle
      slopeDirection
      mechLimitWest
      mechLimitEast
      overnightAngle
      overnightMoveStartHour
      overnightMoveEndHour
      antishadingEnabled
      strongestAngle
      snowStowAngle
      floodStowAngle
      fastPollingInterval
      nodeTemperatureCalibrationEnabled
      nodeTemperatureCalibrationInterval
      windStowTable
      __typename
    }
  }
`;
export const updateNodeParameters = /* GraphQL */ `
  mutation UpdateNodeParameters($input: UpdateNodeParametersInput!) {
    updateNodeParameters(input: $input) {
      id
      label
      newNode
      lowBatteryLimit
      criticalBatteryLimit
      overvoltageThreshold
      highCurrentLimit
      lowBatteryStow
      numberofAngles
      motorOnTime
      motorOffTime
      motorOnTimeStow
      motorOffTimeStow
      angleTolerance
      startTolerance
      startToleranceAntishade
      motionTolerance
      noMotionTime
      directionTolerance
      hardwareCurrentLimit
      otaTimeout
      hotTempHiLimit
      hotTempLowLimit
      coldTempHiLimit
      coldTempLowLimit
      motionFailures
      motorMinSpeed
      rampOnTime
      rampDownPropCons
      overcurrentTries
      overcurrentTime
      arrrStatusIntervalMinute
      directionTime
      waitTimeMotorOff
      waitTimeChargerOff
      limitFailures
      limitTolerance
      directionFailures
      estopDisableTime
      lowBattThresholdDuringMotion
      nodeBattSocStowEnable
      nodeSocBattVoltOffset
      nodeBattSocStowTempHysteresis
      __typename
    }
  }
`;
export const updateUserAttr = /* GraphQL */ `
  mutation UpdateUserAttr($input: UpdateUserAttrInput!) {
    updateUserAttr(input: $input) {
      username
      userCreateDate
      userLastModifiedDate
      enabled
      userStatus
      nameLast
      nameFirst
      email
      company
      companyRole
      allLayouts
      expiration
      __typename
    }
  }
`;
export const updateUserGroups = /* GraphQL */ `
  mutation UpdateUserGroups($input: UpdateUserGroupsInput!) {
    updateUserGroups(input: $input) {
      username
      groups
      __typename
    }
  }
`;
export const setUserLayoutIds = /* GraphQL */ `
  mutation SetUserLayoutIds($input: SetUserLayoutsInput!) {
    setUserLayoutIds(input: $input) {
      items
      nextToken
      __typename
    }
  }
`;
export const userGlobalSignout = /* GraphQL */ `
  mutation UserGlobalSignout($input: CreateGlobalSignoutRequestInput!) {
    userGlobalSignout(input: $input) {
      username
      status
      __typename
    }
  }
`;
export const userResendInvitation = /* GraphQL */ `
  mutation UserResendInvitation(
    $input: CreateUserResendInvitationRequestInput!
  ) {
    userResendInvitation(input: $input) {
      status
      __typename
    }
  }
`;

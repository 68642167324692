import React, { useState } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Button from "@material-ui/core/Button"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const styles = (theme) => ({})

const SelectMasterCoordinates = ({
	classes,
	masters,
	layoutId,
	coordinates,
	updateMasterCoordinates,
	setConfigMasterCoordinates,
	enqueueSnackbar,
}) => {
	const [selected, setSelected] = useState(null)

	const handleAccept = async () => {
		const values = {
			xLoc: coordinates[1],
			yLoc: coordinates[0],
		}
		try {
			await updateMasterCoordinates(selected, layoutId, values.xLoc, values.yLoc)
			setConfigMasterCoordinates(null)
			enqueueSnackbar("Master Location saved to database", {
				variant: "success",
			})
		} catch (err) {
			console.log("updateMasterCoordinates", err)
			enqueueSnackbar("Master Location save failed", {
				variant: "error",
			})
		}
	}

	const handleCancel = () => {
		setConfigMasterCoordinates(null)
	}

	return (
		<React.Fragment>
			<DialogTitle id="confirmation-dialog-title">Assign to Master</DialogTitle>
			<DialogContent>
				<RadioGroup
					aria-label="Master"
					name="master"
					value={selected || ""}
					onChange={(e) => setSelected(e.target.value)}
				>
					{masters.map((master) => (
						<FormControlLabel
							value={master.mLocId}
							key={master.mLocId}
							control={<Radio color="primary" />}
							label={master.name}
						/>
					))}
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} color="primary">
					Cancel
				</Button>
				<Button onClick={handleAccept} disabled={!selected} variant="contained" color="primary">
					Accept
				</Button>
			</DialogActions>
		</React.Fragment>
	)
}

SelectMasterCoordinates.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SelectMasterCoordinates)
